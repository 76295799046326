<!--
    HLP001_help.vue
    ヘルプページ
 -->
<script>
import HeaderArea from "./Header.vue";
import ErrorModal from "@/components/ErrorModal.vue";
import LastAccessDate from "@/common/LastAccessDate";
import StorageExpiration from "@/common/StorageExpiration";
import UpdateAnnouncementModal from "@/components/UpdateAnnouncementModal.vue";
import Cookie from "../common/Cookie.js";
import Color from "../common/Color.js";
import Methods from "@/common/Methods";

export default {
    name: "Help",
    components: {
        HeaderArea,
        ErrorModal,
        UpdateAnnouncementModal,
    },
    data() {
        return {
            // アコーデオン開閉のフラグ（HTML更新時は「p-help__item」の数を必ず確認し、初期化数を合わせるすること）
            isAccordions: [],
            isExitsCookieSelectedSubject: false,
        };
    },
    mounted: function () {
        Methods.checkMoveToTop() ? this.$router.push({ name: "FST001_first" }) : "";
        StorageExpiration.check();
        this.$refs.updateAnnouncementModal.checkUpdate();
        LastAccessDate.update();
        for (let i = 0; i <= 12; i++) {
            this.isAccordions.push = false;
        }
        // cookieにselected_subjectが存在するか確認する
        this.isExitsCookieSelectedSubject = Cookie.exists(Cookie.Key.SELECTED_SUBJECT);
        // cookieにselected_subjectが存在する場合は、bodyの背景色を変更する
        if (this.isExitsCookieSelectedSubject) {
            this.selectedSubject = Cookie.get(Cookie.Key.SELECTED_SUBJECT);
            Color.changeColorBySubject(this.selectedSubject);
        }
    },
    methods: {
        shouldShowHeaderMenu() {
            const selectedSubject = Cookie.get(Cookie.Key.SELECTED_SUBJECT);
            return selectedSubject ? true : false;
        },
        // アコーデオン開閉
        toggle(key) {
            this.isAccordions[key] = !this.isAccordions[key];
        },
        // Mailtoリンク押下時だけアコーデオンを閉じないようにするためのダミーメソッド
        openMailForm() {
        },

        /**
         * エラーモーダルを開く
         */
        openErrorModal(statusCode) {
            this.$refs.errorModal.open(statusCode);
        },
        /**
         * 「シリアルコードの調べ方を開く」
         */
        openSerialCodeSearch() {
            window.open("https://aob.co.jp/digital/smn/#code", "_blank");
        }
    }
};
</script>

<template>
    <body class="input">
        <div class="l-wrapper">
            <HeaderArea
                :logoRedFlag="isExitsCookieSelectedSubject"
                :logoSideFlag="true"
                :menuFlag="shouldShowHeaderMenu()"
                @openErrorModal="openErrorModal"
            />
            <main>
                <div class="l-common-contents__asd-less">
                    <div class="l-common-contents__head">
                        <a @click="$router.go(-1)" class="p-common-contents__head__back-icon">
                            <img src="/assets/img/common/icon_arrow_black.svg" alt="">
                        </a>
                        <div class="p-common-contents__head__title">
                            よくある質問
                        </div>
                    </div>
                    <div class="l-common-contents__body-all" data-background="gray">
                        <div class="p-teaching-unit-list__head">
                            『スマナビ』について
                        </div>

                        <div class="p-teaching-unit-list__body">
                            <div class="p-help__list">
                                <div class="p-help__item" :class="{ 'is-acc-open': isAccordions[0] }" @click="toggle(0)">
                                    <p class="p-help__sentence c-txt--l">

                                        『スマナビ』では具体的にどんなことができますか。
                                    </p>
                                    <p class="p-help__answer c-txt">
                                        テストやプリント等のデジタル教材のダウンロード・印刷・閲覧ができます。
                                    </p>
                                </div>
                                <div class="p-help__item" :class="{ 'is-acc-open': isAccordions[1] }" @click="toggle(1)">
                                    <p class="p-help__sentence c-txt--l">

                                        明日の授業ですぐに使える学習用プリントや資料はありますか。
                                    </p>
                                    <p class="p-help__answer c-txt">
                                        単元ごとの学習用プリントや、前学年の復習プリントもございますので、急な自習にも対応できます。「単元から選ぶ」からコンテンツを検索いただくとスムーズです。
                                        <br>
                                        また、掲示用の漢字カードや計算の公式カード等の学習用資料もございます。「教材から選ぶ」からコンテンツを検索いただくとスムーズです。
                                    </p>
                                </div>
                                <div class="p-help__item" :class="{ 'is-acc-open': isAccordions[2] }" @click="toggle(2)">
                                    <p class="p-help__sentence c-txt--l">

                                        利用にあたり料金・期限や制限などはありますか。
                                    </p>
                                    <p class="p-help__answer c-txt">
                                        本サイトは小学校教員向けのサービスサイトとなっており、ご利用は無料です。
                                        ただし、全てのコンテンツをご利用いただくには弊社の教材（教師用）に同封されたシリアルコードが必要です。シリアルコードを入力すると、対応したコンテンツがご利用いただけます。<br>
                                        ※シリアルコードの有効期限は年度末日までとなっております。年度を跨ぐとシリアルコードが変更になりますので、改めて新年度版の対応教材をご採択いただければご利用が可能となります。
                                    </p>
                                </div>
                                <div class="p-help__item"  :class="{ 'is-acc-open': isAccordions[3] }" @click="toggle(3)">
                                    <p class="p-help__sentence c-txt--l">

                                        本サイトの問い合わせ窓口はどこですか。
                                    </p>
                                    <p class="p-help__answer c-txt">
                                        ご不明な点や、お気付きの点がございましたら、下記までご連絡ください。
                                        <br>
                                        Tel：0120-066-611<br>
                                        E-mail：<a href="mailto:aoba@aob.co.jp?subject=%E3%82%B9%E3%83%9E%E3%83%8A%E3%83%93%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B" class="c-txt--link" @click.stop="openMailForm()">aoba@aob.co.jp</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="p-teaching-unit-list__head">利用方法</div>

                        <div class="p-teaching-unit-list__body">
                            <div class="p-help__list">
                                <div class="p-help__item" :class="{ 'is-acc-open': isAccordions[4] }" @click="toggle(4)">
                                    <p class="p-help__sentence c-txt--l">

                                        シリアルコードがどこにあるかわかりません。
                                    </p>
                                    <p class="p-help__answer c-txt">
                                        <a v-on:click.stop="openSerialCodeSearch()" target="_blank" class="c-txt--link">「シリアルコードの調べ方」</a>から詳しい説明をご覧ください。<br>
                                        シリアルコードを紛失された場合は弊社または特約代理店までお問い合わせください。
                                    </p>
                                </div>
                                <div class="p-help__item" :class="{ 'is-acc-open': isAccordions[5] }" @click="toggle(5)">
                                    <p class="p-help__sentence c-txt--l">

                                        シリアルコードや教科書の設定など、初期設定したものを後から変更することができますか。
                                    </p>
                                    <p class="p-help__answer c-txt">
                                        シリアルコードは画面右上の「シリアルコード追加」から、教科書設定は画面左下の「設定ボタン」からいつでも追加・変更できます。
                                    </p>
                                </div>
                                <div class="p-help__item" :class="{ 'is-acc-open': isAccordions[6] }" @click="toggle(6)">
                                    <p class="p-help__sentence c-txt--l">

                                        シリアルコードの入力を済ませているのに、コンテンツが見られなくなりました。
                                    </p>
                                    <p class="p-help__answer c-txt">
                                        ブラウザソフトのキャッシュやCookieの削除が行われると、登録したシリアルコードの情報が消去されます。<br>
                                        お手数ですが、再度、シリアルコードの登録を行ってください。
                                        <br>
                                        また、シリアルコードの有効期限は年度末日までとなっております。年度を跨ぐとシリアルコードが変更になりますので、改めて新年度版の対応教材をご採択いただければご利用いただけます。<br>
                                    </p>
                                </div>
                                <div class="p-help__item" :class="{ 'is-acc-open': isAccordions[7] }" @click="toggle(7)">
                                    <p class="p-help__sentence c-txt--l">

                                        スマナビの利用を止めるにはどうすればよいですか。
                                    </p>
                                    <p class="p-help__answer c-txt">
                                        利用を止めるにあたって、特定の操作や手続きは必要ありません。
                                    </p>
                                </div>
                                <div class="p-help__item" :class="{ 'is-acc-open': isAccordions[8] }" @click="toggle(8)">
                                    <p class="p-help__sentence c-txt--l">

                                        「このページは存在しません。」等の画面が表示され、利用できません。
                                    </p>
                                    <p class="p-help__answer c-txt">
                                        繰り返し表示される場合は、お手数ですが、お問い合わせください。
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="p-teaching-unit-list__head">
                            利用環境について
                        </div>

                        <div class="p-teaching-unit-list__body">
                            <div class="p-help__list">
                                <div class="p-help__item" :class="{ 'is-acc-open': isAccordions[9] }" @click="toggle(9)">
                                    <p class="p-help__sentence c-txt--l">

                                        利用にあたって個人情報の登録が必要ですか。
                                    </p>
                                    <p class="p-help__answer c-txt">
                                        個人情報の登録は不要です。 <br>
                                        利用履歴や入力内容などはCookie等を利用して保存しています。ご利用状況は弊社管理サーバー側では取得・保存しておりません。
                                    </p>
                                </div>
                                <div class="p-help__item" :class="{ 'is-acc-open': isAccordions[10] }" @click="toggle(10)">
                                    <p class="p-help__sentence c-txt--l">

                                        パソコンやタブレットにアプリ（ソフトウェア）のインストールが必要ですか。
                                    </p>
                                    <p class="p-help__answer c-txt">
                                        インストールは不要です。Webサイトが閲覧できるブラウザソフト（Microsoft
                                        Edge/Google
                                        Chrome/Safari等）から、ご利用いただけます。
                                    </p>
                                </div>
                                <div class="p-help__item" :class="{ 'is-acc-open': isAccordions[11] }" @click="toggle(11)">
                                    <p class="p-help__sentence c-txt--l">

                                        学習用プリントなどをダウンロードしましたが、ファイルが開けません。
                                    </p>
                                    <div class="p-help__answer c-txt">
                                        <ul>
                                            <li>
                                                学習用プリント、評価用資料などのファイルは、「PDF」形式のファイルです。
                                                PDF形式のファイルを閲覧するにはEdgeやGoogle
                                                Chromeなどの標準ブラウザを使用するか、「Adobe
                                                Reader」をご使用ください。
                                            </li>
                                            <li>
                                                得点集計表などの「Excel」ファイルをご利用になる場合は、Microsoft
                                                Office製品のご利用を推奨しております。
                                            </li>
                                            <li>
                                                動画ファイルは「MP4」形式となっておりますので、動画再生ソフトにて再生できます。
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="p-teaching-unit-list__head">
                            著作・権利に関すること
                        </div>

                        <div class="p-teaching-unit-list__body">
                            <div class="p-help__list">
                                <div class="p-help__item" :class="{ 'is-acc-open': isAccordions[12] }" @click="toggle(12)">
                                    <p class="p-help__sentence c-txt--l">

                                        スマナビにある教材を、学校のホームページなどに掲載、教材の加工をしてもよいのでしょうか。
                                    </p>
                                    <p class="p-help__answer c-txt">
                                        本サイト及び関連サービスで提供する情報・教材・サービスについては、以下の特別な条件、制限の下でのみ利用を認めております。
                                        <br>
                                        ①授業における大型モニター画面等での掲示<br>
                                        ②児童の学習端末への公衆送信<br>
                                        ③教材データを印刷しての児童への配布<br>
                                        ※通常、著作物は権利者の許諾なく、個人的な範囲を超える使用目的で複製・転載すること、また、ネットワークを通じて収録された内容を送信できる状態にすることは、著作権法で禁じられております。<br>
                                        ※本サイト及び関連サービスで提供するすべての情報・サービスに係わる著作権、著作者人格権、商標権、工業所有権、その他一切の知的財産権及びノウハウは青葉出版株式会社または記事等の執筆者等正当な権利を有する権利者に帰属します。
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
        <!-- [/l-wrapper] -->

        <UpdateAnnouncementModal ref="updateAnnouncementModal" />
        <ErrorModal ref="errorModal" />

    </body>
</template>
