<!--
    UNI001_units.vue
    単元一覧
 -->

<script>
import SideBar from "./SideBar.vue";
import LastAccessDate from "../common/LastAccessDate.js";
import StorageExpiration from "@/common/StorageExpiration";
import UpdateAnnouncementModal from "@/components/UpdateAnnouncementModal.vue";
import HeaderArea from "./Header.vue";
import ErrorModal from "@/components/ErrorModal.vue";
import { COULD_NOT_GET_SIDEBAR_DATA } from "../const.js";
import Methods from "@/common/Methods";

export default {
  components: {
    SideBar,
    UpdateAnnouncementModal,
    HeaderArea,
    ErrorModal,
  },
  data() {
    return {
      grades: [],
      searching: true,
      message: COULD_NOT_GET_SIDEBAR_DATA,
    };
  },
  mounted: function () {
    Methods.checkMoveToTop() ? this.$router.push({ name: "FST001_first" }) : "";
    StorageExpiration.check();
    this.$refs.updateAnnouncementModal.checkUpdate();
    LastAccessDate.update();

    const header = document.querySelector(".l-header");
    if (header) {
      header.scrollIntoView(true);
    }
  },
  methods: {
    // 全て開く/全て閉じるの実装
    accordionAllOpen: (event) => {
      // 親要素を取得
      const wrapper = event.target.closest(".p-teaching-unit-list__wrapper");

      // アコーディオンを取得
      const accordionItems = wrapper.querySelectorAll(
        ".p-teaching-unit-list__body__content"
      );

      // 開いているアコーディオンを配列に入れる
      const openAccordion = Array.from(accordionItems).filter((item) => {
        return item.classList.contains("is-open");
      });

      if (openAccordion.length === accordionItems.length) {
        // 全てのアコーディオンが開いている時の処理を記述
        accordionItems.forEach((accordionItem) => {
          accordionItem.classList.remove("is-open");
        });
        event.target.classList.remove("is-open");
        event.target.innerText = "全て開く";
      } else {
        // 一つでもアコーディオンが閉まっている時の処理を記述
        accordionItems.forEach((accordionItem) => {
          accordionItem.classList.add("is-open");
        });
        event.target.classList.add("is-open");
        event.target.innerText = "折りたたむ";
      }
    },

    // アコーディオン開閉の実装
    accordionOpen: (event) => {
      event.preventDefault();

      // 開閉するアコーディオンを取得
      const targetAccordion = event.target.closest(
        ".p-teaching-unit-list__body__content"
      );
      // アコーディオンの開閉
      targetAccordion.classList.toggle("is-open");

      // 学年のブロックを取得
      const wrapper = event.target.closest(".p-teaching-unit-list__wrapper");
      // アコーディオンを取得
      const accordionItems = wrapper.querySelectorAll(
        ".p-teaching-unit-list__body__content"
      );
      // 開いているアコーディオンを配列に入れる
      const openAccordion = Array.from(accordionItems).filter((item) => {
        return item.classList.contains("is-open");
      });
      // 全て開く/全て閉じるボタンを取得
      const openAllBtn = wrapper.querySelector(".p-common-contents__btn-all");

      if (openAccordion.length === accordionItems.length) {
        // 全てのアコーディオンが開いている時の処理を記述
        openAllBtn.classList.add("is-open");
        openAllBtn.innerText = "折りたたむ";
      } else {
        // 一つでもアコーディオンが閉まっている時の処理を記述
        openAllBtn.classList.remove("is-open");
        openAllBtn.innerText = "全て開く";
      }
    },

    // アコーディオンの要素を取得する
    getAccordionItems() {
      // コンテンツ内の全て開く/全て閉じるボタンを全て取得して配列に入れる
      const openAllBtns = document.querySelectorAll(
        ".p-common-contents__btn-all.c-txt--xs"
      );
      // openAllBtnsの中にある学年のブロックを取得して配列に入れる
      const wrappers = Array.from(openAllBtns).map((openAllBtn) => {
        return openAllBtn.closest(".p-teaching-unit-list__wrapper");
      });
      // 学年毎にアコーディオン要素を配列に格納
      const accordionItems = wrappers.map((wrapper) => {
        return wrapper.querySelectorAll(".p-teaching-unit-list__body__content");
      });
      return accordionItems;
    },

    // アコーディオンが全て開いているかどうかを判定する
    refreshAllBtn() {
      // アコーディオンの要素を取得
      const accordionItems = this.getAccordionItems();
      // accordionItemsの中にある配列の中を全てチェックして、全て開いているかどうかを判定する
      accordionItems.forEach((accordionItem) => {
        // 開いているアコーディオンを配列に入れる
        const openAccordion = Array.from(accordionItem).filter((item) => {
          return item.classList.contains("is-open");
        });
        // 全て開く/全て閉じるボタンを取得
        const openAllBtn = accordionItem[0]
          .closest(".p-teaching-unit-list__wrapper")
          .querySelector(".p-common-contents__btn-all");
        // 全て開いているかどうかを判定する
        this.isAllOpen(openAccordion, accordionItem, openAllBtn);
      });
    },

    // アコーディオンが全て開いているかどうかを判定する
    isAllOpen(openAccordion, accordionItem, openAllBtn) {
      // 全て開いているかどうかを判定する
      if (openAccordion.length === accordionItem.length) {
        // 全てのアコーディオンが開いている時の処理を記述
        openAccordion.forEach((accordion) => {
          accordion.classList.add("is-open");
        });
        openAllBtn.classList.add("is-open");
        openAllBtn.innerText = "折りたたむ";
      } else {
        // 一つでもアコーディオンが閉まっている時の処理を記述
        openAllBtn.classList.remove("is-open");
        openAllBtn.innerText = "全て開く";
      }
    },

    /*
     * 検索結果を取得
     */
    jsonData(data) {
      this.grades = data.grades;
      this.searching = false;
      // nextTickでDOMが更新された後に実行
      this.$nextTick(() => {
        this.refreshAllBtn();
      });
    },

    /*
     * 改行コードを<br>に変換する
     * @param {string} msg
     * @return {string} msg
     */

    htmlText(message) {
      if (message) {
        return message.replace(/\r?\n/g, "<br>");
      }
    },

    /**
     * エラーモーダルを開く
     */
    openErrorModal(statusCode) {
      this.$refs.errorModal.open(statusCode);
    },
  },
};
</script>

<template>
  <div class="l-wrapper">
    <!-- 一般的 -->
    <HeaderArea
      :logoRedFlag="true"
      :logoSideFlag="true"
      :menuFlag="true"
      @openErrorModal="openErrorModal"
    />
    <div class="l-contents teaching-unit">
      <SideBar
        v-on:getData="jsonData"
        @openErrorModal="openErrorModal"
      />
      <main
        class="l-common-contents"
        v-if="this.grades.length === 0 && !searching"
      >
        <div class="l-common-contents__body" data-background="subject">
          <div class="l-common-contents__body__error">
            <p
              class="p-common-contents__body__error"
              v-html="htmlText(message)"
            ></p>
          </div>
        </div>
      </main>

      <main class="l-common-contents" v-else>
        <div class="l-common-contents__body" data-background="subject">
          <div
            class="p-teaching-unit-list__wrapper"
            v-for="grade in this.grades"
            v-bind:key="grade.gradeName"
          >
            <div class="p-teaching-unit-list__heading">
              <div class="p-teaching-unit-list__head-title">
                {{ grade.gradeName }}
              </div>
              <button
                class="p-common-contents__btn-all c-txt--xs"
                v-on:click="accordionAllOpen($event)"
              >
                全て開く
              </button>
            </div>
            <div class="p-teaching-unit-list__body">
              <div
                class="p-teaching-unit-list__body__container-semester"
                v-for="terms in grade.terms"
                v-bind:key="terms.termName"
              >
                <div
                  class="p-teaching-unit-list__body__semester-level c-txt c-txt--bold"
                >
                  {{ terms.termName }}
                </div>
                <div class="p-teaching-unit-list__body__container-content">
                  <router-link
                    v-for="units in terms.units"
                    v-bind:key="units.unitId"
                    :to="{
                      name: 'UNI002_units_contents',
                      params: { unitId: units.unitId },
                    }"
                    class="p-teaching-unit-list__body__content"
                  >
                    <div
                      class="p-teaching-unit-list__body__container__content-title"
                    >
                      <div
                        class="p-teaching-unit-list__body__content-title c-txt--l c-txt--bold"
                      >
                        {{ units.unitName }}
                      </div>
                      <div
                        class="p-teaching-unit-list__body__content-img"
                        v-on:click="accordionOpen($event)"
                      ></div>
                    </div>
                    <div
                      class="p-teaching-unit-list__body__content__container-txt"
                    >
                        <button
                          class="p-teaching-unit-list__body__content-txt c-txt--xs js-link"
                          v-for="materialTypes in units.materialTypes"
                          v-bind:key="materialTypes.Id"
                          v-on:click.prevent="
                            $router.push({
                              name: 'UNI002_units_contents',
                              params: { unitId: units.unitId },
                              hash: '#' + materialTypes.materialTypeId,
                            })
                          "
                        >
                          {{ materialTypes.abbreviation }}
                        </button>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <!-- [/l-contents] -->
  </div>
  <!-- [/l-wrapper] -->

  <UpdateAnnouncementModal ref="updateAnnouncementModal" />
  <ErrorModal ref="errorModal" />

</template>
