// Description: 定数を定義するファイル

// 教科名の配列
const SUBJECT = {};

SUBJECT["national-language"] = "国語";
SUBJECT["math"] = "算数";
SUBJECT["science"] = "理科";
SUBJECT["society"] = "社会";
SUBJECT["english"] = "英語";
SUBJECT["music"] = "音楽";
SUBJECT["copying"] = "書写";
SUBJECT["hygiene"] = "保健";
SUBJECT["family"] = "家庭";
SUBJECT["living"] = "生活";
SUBJECT["morals"] = "道徳";
SUBJECT["other"] = "その他";
SUBJECT["drawing"] = "図工";

// 教科名の配列
const SUBJECT_KEYS = {};

SUBJECT_KEYS["国語"] = "national-language";
SUBJECT_KEYS["算数"] = "math";
SUBJECT_KEYS["理科"] = "science";
SUBJECT_KEYS["社会"] = "society";
SUBJECT_KEYS["英語"] = "english";
SUBJECT_KEYS["音楽"] = "music";
SUBJECT_KEYS["書写"] = "copying";
SUBJECT_KEYS["保健"] = "hygiene";
SUBJECT_KEYS["図工"] = "drawing";
SUBJECT_KEYS["家庭"] = "family";
SUBJECT_KEYS["生活"] = "living";
SUBJECT_KEYS["道徳"] = "morals";
SUBJECT_KEYS["その他"] = "other";

// 教科(body)の配列
const SUBJECT_COLORS = [
    {
        name: 'national-language',
        color: {
            main: '#e44a4a',
            sub: '#fef6f6',
            main_hover: '#D94646',
            sub_hover: '#F6EFEF',
            thin: 'rgba(228, 74, 74, 0.2)',
            contents_body: '#FEE9E9'
        }
    },
    {
        name: 'math',
        color: {
            main: '#2b5fe5',
            sub: '#f4f7fe',
            main_hover: '#295ADA',
            sub_hover: '#EDF0F6',
            thin: 'rgba(43, 95, 229, 0.2)',
            contents_body: '#E7EEFE'
        }
    },
    {
        name: 'science',
        color: {
            main: '#569f0d',
            sub: '#f7faf3',
            main_hover: '#52970C',
            sub_hover: '#F0F3EC',
            thin: 'rgba(86, 159, 13, 0.2)',
            contents_body: '#F2FAE7'
        }
    },
    {
        name: 'society',
        color: {
            main: '#d45a01',
            sub: '#fdf7f2',
            main_hover: '#C95501',
            sub_hover: '#F5F0EB',
            thin: 'rgba(212, 90, 1, 0.2)',
            contents_body: '#FFEBCD'
        }
    },
    {
        name: 'english',
        color: {
            main: '#d08900',
            sub: '#fdf9f2',
            main_hover: '#C68200',
            sub_hover: '#F5F2EB',
            thin: 'rgba(208, 137, 0, 0.2)',
            contents_body: '#FDF5D0'
        }
    },
    {
        name: 'copying',
        color: {
            main: '#ae0c00',
            sub: '#fbf3f2',
            main_hover: '#A50B00',
            sub_hover: '#F3ECEB',
            thin: 'rgba(174, 12, 0, 0.2)',
            contents_body: '#FFE4E1'
        }
    },
    {
        name: 'family',
        color: {
            main: '#e32d84',
            sub: '#fef5f9',
            main_hover: '#D82B7D',
            sub_hover: '#F6EEF2',
            thin: 'rgba(227, 45, 132, 0.2)',
            contents_body: '#FEE8F2'
        }
    },
    {
        name: 'hygiene',
        color: {
            main: '#239ad6',
            sub: '#f4fafd',
            main_hover: '#2192CB',
            sub_hover: '#EDF3F5',
            thin: 'rgba(35, 154, 214, 0.2)',
            contents_body: '#E7F6FD'
        }
    },
    {
        name: 'music',
        color: {
            main: '#c953cb',
            sub: '#fcf6fc',
            main_hover: '#BE4FC1',
            sub_hover: '#F4EFF4',
            thin: 'rgba(201, 83, 203, 0.2)',
            contents_body: '#FCE9FC'
        }
    },
    {
        name: 'living',
        color: {
            main: '#839307',
            sub: '#f9faf3',
            main_hover: '#7C8C07',
            sub_hover: '#F2F3EC',
            thin: 'rgba(131, 147, 7, 0.2)',
            contents_body: '#F7FAE7'
        }
    },
    {
        name: 'drawing',
        color: {
            main: '#7a4908',
            sub: '#f8f6f3',
            main_hover: '#744508',
            sub_hover: '#F1EFEC',
            thin: 'rgba(122, 73, 8, 0.2)',
            contents_body: '#FAE3C3'
        }
    },
    {
        name: 'morals',
        color: {
            main: '#4c9097',
            sub: '#f6f9fa',
            main_hover: '#48898F',
            sub_hover: '#EFF2F3',
            thin: 'rgba(76, 144, 151, 0.2)',
            contents_body: '#EAF6FA'
        }
    },
    {
        name: 'other',
        color: {
            main: '#4b4593',
            sub: '#f6f6fa',
            main_hover: '#47428C',
            sub_hover: '#EFEFF3',
            thin: 'rgba(75, 69, 147, 0.2)',
            contents_body: 'rgba(75, 69, 147, 0.1)'
        }
    }
];

// コンテンツの種類
const PDF = "pdf";
const FILE_EXTENSION_MOVIE = "mp4";
const FILE_EXTENSION_PDF = "pdf";

// APIアクセス用のroot URL
const API_URL = process.env.VUE_APP_API_BASE_URL;

// 単元一覧
const UNITS = "/units";
// 教材一覧
const MATERIALS = "/materials";
// キーワード検索
const CONTENTS_SEARCH = "/contents/search";
// 教科一覧取得
const SUBJECTS = "/subjects";
// 教科対応学年取得
const SUBJECT_GRADES = "/subject-grades";
// 教科書準拠取得API
const API_URL_TEXTBOOK_COMPLIANCES = `${API_URL}/textbook-compliances`;
// 有効シリアルコード数取得API
const API_URL_SERIAL_NUMBERS_VALID_COUNT = `${API_URL}/serial-numbers/valid-count`;
// シリアル番号判定絵API
const API_URL_SERIAL_NUMBERS_VALIDATE = `${API_URL}/serial-numbers/validate`;
// 会員種別取得API
const API_URL_MEMBER_TYPES = `${API_URL}/member-types`;

// 画面名
const SCREEN_NAME = [];
SCREEN_NAME["UNI"] = "UNI001_units";
SCREEN_NAME["MTR"] = "MTR001_materials";
SCREEN_NAME["SRC"] = "SRC001_search-contents";

// LocalStorageのキー
const LS_KEY_TEXTBOX = "textbooks";
const LS_KEY_USED_CONTENTS = "used_contents";
const LS_KEY_USED_CONTENTS_EXPIRES_ON = "used_contents_expires_on";
const LS_KEY_SELECTED_TERMS = "selected_terms";
const LS_KEY_SELECTED_GRADES = "selected_grades";
const LS_KEY_SELECTED_SCREEN_GROUP = "selected_screen_group";

// LocalStorageの各キーの有効期限情報Suffix
const LS_SUFFIX_EXPIRES_ON = "_expires_on";

// 取得できなかったときのメッセージ
// 表示時に改行して表示したい

const COULD_NOT_GET_SIDEBAR_DATA =
  "表示できるコンテンツがありません。\n\
  左の「学期」「学年」選択ボタンから設定を変更してください。";

const COULD_NOT_GET_SEARCH_DATA =
  "検索条件に合うコンテンツが見つかりませんでした。";

export {
  SUBJECT,
  SUBJECT_KEYS,
  SUBJECT_COLORS,
  SUBJECTS,
  PDF,
  FILE_EXTENSION_MOVIE,
  FILE_EXTENSION_PDF,
  LS_KEY_TEXTBOX,
  LS_KEY_USED_CONTENTS,
  LS_KEY_USED_CONTENTS_EXPIRES_ON,
  LS_KEY_SELECTED_TERMS,
  LS_KEY_SELECTED_GRADES,
  LS_KEY_SELECTED_SCREEN_GROUP,
  LS_SUFFIX_EXPIRES_ON,
  API_URL,
  UNITS,
  MATERIALS,
  CONTENTS_SEARCH,
  API_URL_TEXTBOOK_COMPLIANCES,
  API_URL_SERIAL_NUMBERS_VALID_COUNT,
  API_URL_SERIAL_NUMBERS_VALIDATE,
  SUBJECT_GRADES,
  SCREEN_NAME,
  API_URL_MEMBER_TYPES,
  COULD_NOT_GET_SIDEBAR_DATA,
  COULD_NOT_GET_SEARCH_DATA,
};
