<!--
    FST001_first.vue
    初回画面
-->
<style scoped>
/*
＊ カルーセルの非活性状態時に被っている広告リンクを踏まないようにするCSS
*/
.swiper-button-next.swiper-button-disabled
{
    opacity:.35;
    cursor:auto;
    pointer-events: auto;
}
.swiper-button-prev.swiper-button-disabled
{
    opacity:.35;
    cursor:auto;
    pointer-events: auto;
}
</style>
<script>

    import {
        API_URL,
        SCREEN_NAME
    } from "../const.js";
    import axios from "axios";
    import $ from "jquery";
    import Swiper, { Navigation, Pagination } from 'swiper';
    import 'swiper/css';
    import 'swiper/css/navigation';
    import 'swiper/css/pagination';
    import LastAccessDate from "../common/LastAccessDate.js";
    import StorageExpiration from "@/common/StorageExpiration";
    import Cookie from "@/common/Cookie";
    import Storage from "@/common/Storage";
    import TermsArea from "@/components/TRM001_terms-use.vue";
    import InformationDetailModal from "@/components/InformationDetailModal.vue"
    import ErrorModal from "@/components/ErrorModal.vue";
    import Methods from "@/common/Methods";

    export default {
        name: "firstView",
        components: {
            TermsArea,
            InformationDetailModal,
            ErrorModal,
        },
        data() {
            return {
                informationData: [],
                advertisingBanners: [],
                // 動画再生モーダル関連
                currentTimeText: null,
                currentVolume: 100,
                progressBarWidth: 0,
                isMouseDown: false,
                startX: 0,
                pastX: 0,
                isPlaying: false,
                isMute: false,
                moveFlg: false,
                audioContext: null,
                videoSource: null,
                volumeNode: null,
            };
        },
        mounted: function () {
            StorageExpiration.check();

            this.checkReferrer();
            // 教材画面へ遷移した場合は、遷移以降の処理をスキップする
            if(this.moveFlg){
                return;
            }
            this.changeScreen();

            LastAccessDate.update();

            $('a[href^="#"]').on('click.smoothScroll', function () {
                const href = $(this).attr('href'),
                    $target = $(href === '#' ? 'html' : href);

                if (!$target.length) return;

                let offset = 0; // スクロール位置をずらす場合は、条件分岐等を行う

                //画面幅
                let windowWidth = window.innerWidth;
                //sp
                let windowSp = 1024;

                if (windowWidth > windowSp) {
                    const position = $target.offset().top + offset - 80 + 'px';
                    $('html, body').animate({ scrollTop: position }, 400, 'swing');
                } else {
                    const position = $target.offset().top + offset - 52 + 'px';
                    $('html, body').animate({ scrollTop: position }, 400, 'swing');
                }

                return false;
            });

            const modalBtns = document.querySelectorAll('.modalOpen');
            modalBtns.forEach(function (btn) {
                btn.onclick = function () {
                    var modal = btn.getAttribute('data-modal');
                    document.getElementById(modal).classList.add('is-appear');
                };
            });

            const closeBtns = document.querySelectorAll('.modalClose');
            closeBtns.forEach(function (btn) {
                btn.onclick = function () {
                    var modal = btn.closest('.modal');
                    modal.classList.remove('is-appear')
                };
            });

            // 動画モーダルを表示・非表示
            const topVideoElement = document.getElementById('topVideo');
            const videoBtn = document.querySelectorAll('.p-first-mv__video');
            const video = document.getElementById('video');
            const playBtn = document.getElementById('playBtn');
            if (videoBtn.length !== 0) {
                const closeBtn = document.querySelector('.js-closeBtn');
                videoBtn.forEach((target) => {
                    target.addEventListener('click', () => {
                        if (!topVideoElement.classList.contains('is-appear')) {
                            if(this.audioContext === null){
                            // 再生中の初期値設定
                            this.isPlaying = false;
                            this.isMute = false;
                            // 動画の設定値を取得
                            this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
                            this.videoSource = this.audioContext.createMediaElementSource(this.$refs.video);
                            this.volumeNode = this.audioContext.createGain();
                            this.videoSource.connect(this.volumeNode);
                            this.volumeNode.connect(this.audioContext.destination);
                            // 動画の設定値を取得
                            this.progressBarWidth = this.$refs.progressBar.clientWidth;
                            // 初期音量の処理
                            this.$refs.volumeBar.value / 100;
                            this.volumeNode.gain.value = this.$refs.volumeBar.value / 100;
                            // ミュート状態の時は音量を0にする
                            if(this.isMute) {
                                this.volumeNode.gain.value = 0;
                            }
                            // スライド時にプログレスバーの色を設定
                            this.updateSlider(this.$refs.volumeBar);
                            }
                            // 動画モーダルの表示
                            topVideoElement.classList.add('is-appear');
                        }
                    });
                });

                closeBtn.addEventListener('click', () => {
                    if (topVideoElement.classList.contains('is-appear')) {
                        topVideoElement.classList.remove('is-appear')
                        playBtn.classList.add('is-stop');
                        playBtn.classList.remove('is-play');
                        video.pause();
                        video.currentTime = 0;
                    }
                });

                // 動画モーダルの枠外がクリックされた場合はモーダルを閉じる
                topVideoElement.addEventListener('click', (event) => {
                    if (event.target.closest("#topVideoInner") === null) {
                        closeBtn.click();
                    }
                });
            }

            axios
            .get(API_URL + "/informations")
            .then((response) => {
                this.informationData = response.data;
                this.scrollNewsList();
            })
            .catch((error) => {
                console.log("error: " + error);
            });

            axios
            .get(API_URL + "/advertising-banners")
            .then((response) => {
                this.advertisingBanners = response.data;
            })
            .catch((error) => {
                console.log("error: " + error);
            });

            // カルーセルの初期化
            this.initCarousel()

        },
        methods: {
            /**
             * 外部サイトから遷移してきた場合、最後に表示していた検索画面へリダイレクト
             */
            checkReferrer() {
                const referrer = document.referrer;
                const currentHost = window.location.origin;

                // リファラが存在し、同一ドメインの場合は処理をスキップ
                // リファラの存在チェックの理由は、new URL(referrer).origin でエラーが発生するため
                if (referrer && new URL(referrer).origin.startsWith(currentHost)) {
                    return;
                }

                if (!LastAccessDate.isToday()) {
                    return;
                }

                const selectedSubject = Cookie.get(Cookie.Key.SELECTED_SUBJECT);
                if (!selectedSubject) {
                    return;
                }

                const selectedScreenGroups = Storage.getJson(Storage.Key.SELECTED_SCREEN_GROUPS);
                if (!selectedScreenGroups) {
                    return;
                }

                const lastScreenGroup = selectedScreenGroups.find((screenGroup) => screenGroup.subject === selectedSubject);
                if (!lastScreenGroup) {
                    return;
                }

                this.$router.push({ name: SCREEN_NAME[lastScreenGroup.screenGroup] });
                this.moveFlg = true;
            },
            getScrollVerticalPosition(e) {
                const {
                scrollHeight, scrollTop, clientHeight
                } = e.target;

                const isScrollTop = scrollTop === 0;
                const isScrollBottom = scrollHeight - clientHeight === scrollTop;

                if (isScrollTop) {
                return 'top';
                } else if (isScrollBottom) {
                return 'bottom';
                } else {
                return 'scrolling';
                }
            },
            scrollNewsList() {
                const newsItems = document.querySelectorAll('.p-first-news__item');
                const gradientTop = document.getElementById('gradient-top');
                const gradientBottom = document.getElementById('gradient-bottom');

                if (newsItems.length <= 2) {
                    gradientBottom.style.display = "none";
                    gradientTop.style.display = "none";
                    return;
                }
                const scrollArea = document.getElementById('scroll-area');
                scrollArea.addEventListener('scroll', e => {
                    const scrollPosition = this.getScrollVerticalPosition(e);
                    if(scrollPosition === 'top'){
                        gradientTop.style.display = "none";
                        gradientBottom.style.display = "block";
                    }else if(scrollPosition === 'bottom'){
                        gradientTop.style.display = "block";
                        gradientBottom.style.display = "none";
                    }else{
                        gradientBottom.style.display = "none";
                        gradientTop.style.display = "none";
                    }
                });
            },

            LinkOrOpenInfoModal(information) {
                if(information.body) {
                    this.$refs.informationDetailModal.open(information);
                } else if (information.linkUrl) {
                    Methods.openTab(information.linkUrl);
                }
            },
            LinkBannerUrl(linkUrl) {
                // 広告バナーリンクを別タブで開く
                Methods.openTab(linkUrl);
            },
            LinkStart() {
                // cookieからselected_subjectを取得
                this.selectedSubject = Cookie.get('selected_subject')
                if (this.selectedSubject == null) {
                    this.$router.push({ name: 'FST002_settings_serial-number_first' });
                } else {
                    // cookieに保持された科目が存在する場合
                    // URLにcontentsを含む画面から遷移した場合は、ローカルストレージのselected-screen-groupを取得する
                    let screenGroupObjects = Storage.getJson(Storage.Key.SELECTED_SCREEN_GROUPS);
                    let targetObject = screenGroupObjects ? screenGroupObjects.find((screenGroup) => screenGroup.subject == this.selectedSubject) : '';
                    if (targetObject === undefined || targetObject === '') {
                        this.$router.push({ name: "UNI001_units" });
                    } else {
                        this.$router.push({ name: SCREEN_NAME[targetObject.screenGroup] });
                    }
                }
            },
            // 全画面の切り替え
            changeScreen() {
                const body = document.querySelector('.preview-video');
                const fullScreenBtn = document.getElementById('fullScreenBtn');
                const target = document.getElementById('fullScreenContent');
                // 全画面/全画面を抜けるボタンを押下した時、フルスクリーンの制御を行う
                fullScreenBtn.addEventListener('click', () => {
                    if (!document.webkitFullscreenElement) {
                        target.webkitRequestFullscreen();
                    } else {
                        document.webkitExitFullscreen();
                    }
                });
                // 全画面/全画面から抜けた際にクラスを付与
                document.addEventListener('webkitfullscreenchange', function () {
                    if (document.webkitFullscreenElement) {
                        // ビデオが全画面になったときの処理
                        body.classList.add('is-full');
                    } else {
                        // ビデオが全画面から抜けたときの処理
                        body.classList.remove('is-full');
                    }
                });
            },
            // プログレスバー押下時
            inputProgressBar () {
                this.$refs.video.currentTime = (this.$refs.video.duration * this.$refs.progressBar.value) / 100;
                this.updateSlider(this.$refs.progressBar);
            },
            // スライド時にプログレスバーの色を設定
            updateSlider (slider) {
                const progress = (slider.value / slider.max) * 100;
                slider.style.background = `linear-gradient(to right, #2b5fe5 ${progress}%, #ababab ${progress}%)`;
            },
            // 動画再生・停止
            clickPlayButton() {
                if (this.isPlaying === false) {
                    this.$refs.video.play();
                    this.isPlaying = true;
                } else {
                    this.$refs.video.pause();
                    this.isPlaying = false;
                }
                this.audioContext.resume();
            },
            // ミュートボタン押下時
            clickVolumeButton() {
                if (this.isMute === false) {
                    this.isMute = true;
                    this.volumeNode.gain.value = 0;
                    this.currentVolume = this.$refs.volumeBar.value;
                    this.$refs.volumeBar.value = 0;
                } else if (this.isMute === true && this.currentVolume / 100 !== 0) {
                    this.isMute = false;
                    this.$refs.volumeBar.value = this.currentVolume;
                    this.volumeNode.gain.value = this.$refs.volumeBar.value / 100;
                }
                this.updateSlider(this.$refs.volumeBar);
            },
            // 音量バークリック時
            inputVolumeBar() {
                if(this.$refs.volumeBar.value / 100 === 0) {
                    this.isMute = true;
                    this.volumeNode.gain.value = 0;
                } else {
                    this.isMute = false;
                    this.volumeNode.gain.value = this.$refs.volumeBar.value / 100;
                }
                this.updateSlider(this.$refs.volumeBar);
            },
            // 再生時間はmountedで取得できないため、ページ読み込み完了後に実行させる
            loadeddataVideo() {
                // 教材画面へ遷移した場合は、コンソールエラー対策のため処理をスキップする
                if(this.moveFlg){
                    return;
                }
                this.timeupdateVideo();
            },
            // 動画再生中は再生位置を更新する
            timeupdateVideo() {
                // 動画の長さを取得
                const fullTime = this.$refs.video.duration;
                const totalMinutes = Math.floor(fullTime / 60);
                const totalSeconds = Math.floor(fullTime % 60);
                const totalTimeString = `${totalMinutes}:${
                    totalSeconds < 10 ? '0' : ''
                }${totalSeconds}`;

                // 再生時間を取得
                let nowTime = this.$refs.video.currentTime;
                const setPos = (nowTime / fullTime) * 100;
                this.$refs.progressBar.value = setPos;
                const minutes = Math.floor(nowTime / 60);
                const seconds = Math.floor(nowTime % 60);
                const currentTimeString = `${minutes}:${
                    seconds < 10 ? '0' : ''
                }${seconds}`;

                this.currentTimeText = `${currentTimeString}/${totalTimeString}`;
                this.updateSlider(this.$refs.progressBar);
            },
            // 動画終了時は再生ボタン切り替え
            endedVideo() {
                this.isPlaying = false;
            },
            // [SUB]現在再生中の動画の位置を割合で取得
            getVideoPositionToPer(pageX) {
                return (pageX - (this.$refs.progressBar.getBoundingClientRect().left + window.scrollX)) /
                    this.$refs.progressBar.clientWidth;
            },
            // [SUB]現在音量の位置を割合で取得
            getVolumePositionToPer(pageX) {
                return (pageX - (this.$refs.volumeBar.getBoundingClientRect().left + window.scrollX)) /
                    this.$refs.volumeBar.clientWidth;
            },

            /**
             * エラーモーダルを開く
             */
            openErrorModal(statusCode) {
                this.$refs.errorModal.open(statusCode);
            },
            /**
             * カルーセルの初期化
             */
            initCarousel() {
                const slide = document.querySelectorAll('.swiper-slide');
                const slidesPerView = 3;

                new Swiper('.swiper', {
                    modules: [Navigation, Pagination],
                    slidesPerView: 1.5,
                    spaceBetween: 20,
                    breakpoints: {
                    // 768px以上の場合
                        768: {
                            slidesPerView: slidesPerView,
                            loop: slide.length > slidesPerView,
                            loopedSlides:
                                slide.length > slidesPerView ? slidesPerView : null, // 追加
                            // ナビボタンが必要なら追加
                            navigation: {
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev'
                            },
                            watchOverflow: true
                        }
                    }
                });
            }
        },
    }
</script>
<template>
    <body class="first">
        <div class="l-wrapper">

<header class="l-header first">
    <div class="l-header__container -top">
        <h1 class="l-header__logo">
            <a href="https://www.aob.co.jp/" class="l-header__logo-link">
                <picture>
                    <source
                        srcset="/assets/img/first/header/icon_first_logo.svg"
                        media="(min-width: 769px)"
                    />
                    <img
                        src="/assets/img/first/header/icon_first_logo_sp.svg"
                        alt="青葉出版"
                    />
                </picture>
            </a>
        </h1>
        <nav class="l-header-nav">
            <ul class="l-header-nav__list">
                <li class="l-header-nav__item c-txt--s">
                    <a href="https://aob.co.jp/digital/smn/" target="_blank" class="l-header-nav__link"
                        >使い方</a
                    >
                </li>
                <li class="l-header-nav__item c-txt--s">
                    <div class="l-header-nav__link modalOpen" data-modal="modal-term">
                        <a class="c-btn">利用規約</a>
                    </div>
                </li>
                <li class="l-header-nav__item c-txt--s">
                    <a href="mailto:aoba@aob.co.jp?subject=%E3%82%B9%E3%83%9E%E3%83%8A%E3%83%93%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B" class="l-header-nav__link">お問い合わせ</a>
                </li>
                <li class="l-header-nav__item is-appear" id="startBtnPc">
                    <div class="c-btn--free">
                        <a
                            href="#"
                            @click="LinkStart()"
                            class="c-btn c-btn__txt c-btn--bg-quaternary"
                        >
                            START
                        </a>
                    </div>
                </li>
            </ul>
        </nav>
        <!-- [/l-header-nav] -->
        <div class="l-header-sp-trigger is-appear" id="startBtnTab">
            <a
                href="javascript:void(0);"
                @click="LinkStart()"
                class="c-btn c-btn__txt c-txt c-btn--bg-quaternary">
                START
            </a>
        </div>
        <!-- [/l-header-sp-trigger] -->
    </div>
</header>
<!-- [/l-header] -->


            <main class="l-contents first">
                <article>
                    <!-- mv -->
                    <section class="p-first-mv">
                        <div class="p-first-mv__img">
                            <picture>
                                <source
                                    srcset="/assets/img/first/mv/img_mv.png"
                                    media="(min-width: 768px)"
                                />
                                <img
                                    src="/assets/img/first/mv/img_mv_sp.png"
                                    alt=""
                                />
                            </picture>
                        </div>
                        <div class="p-first-mv__parts">
                            <div class="p-first-mv__container-copy">
                                <div class="p-first-mv__copy">
                                    <picture>
                                        <source
                                            srcset="/assets/img/first/mv/img_mv_copy.svg"
                                            media="(min-width: 1024px)"
                                        />
                                        <source
                                            srcset="
                                                    /assets/img/first/mv/img_mv_copy_tb.svg
                                                    "
                                            media="(min-width: 768px)"
                                        />
                                        <img
                                            src="/assets/img/first/mv/img_mv_copy_sp.svg"
                                            alt=""
                                        />
                                      </picture>
                                </div>
                            </div>
                            <div class="p-first-mv__btn">
                                <a
                                    href="#"
                                    @click="LinkStart()">START
                                </a>
                            </div>
                        </div>
                        <div class="p-first-mv__container-video">
                            <p class="p-first-mv__video-txt c-txt--s">
                                動画で使い方をチェック！
                            </p>
                            <button class="p-first-mv__video">
                                <img
                                    src="/assets/img/first/mv/img_mv_thumbnail.jpg"
                                    alt=""
                                />
                            </button>
                        </div>
                    </section>
                    <!-- mv -->

                    <!-- video -->
                    <div class="p-first-video" data-background="white">
                        <p>動画で使い方をチェック！</p>
                        <button class="p-first-mv__video">
                            <picture>
                                <source
                                    srcset="/assets/img/first/mv/img_mv_thumbnail.jpg"
                                    media="(min-width: 768px)"
                                />
                                <img
                                    src="/assets/img/first/mv/img_mv_thumbnail.jpg"
                                    alt=""
                                />
                            </picture>
                        </button>
                    </div>
                    <!-- video -->

                    <!-- news -->
                    <div class="p-first-news" v-show="informationData.length">
                        <span id="gradient-top"></span>
                        <span id="gradient-bottom"></span>
                        <div
                            class="p-first-news__list"
                            id="scroll-area"
                        >
                            <button
                                class="p-first-news__item"
                                v-for="(information, idx) in informationData"
                                :key="idx"
                                @click="LinkOrOpenInfoModal(information)"
                            >
                                <span class="c-txt--xxxs">{{ information.informationTypeName }}</span>
                                <span class="c-txt--xxxs">{{ information.displayDate }}</span>
                                <p class="c-txt--xs">{{ information.title }}</p>
                            </button>
                        </div>
                    </div>
                    <!-- news -->

                    <!-- new-product -->
                    <section class="p-first-new-product" v-show="advertisingBanners.length">
                        <div class="swiper-content">
                            <div class="swiper">
                                <div class="swiper-wrapper">
                                    <div
                                        class="swiper-slide"
                                        v-for="(advertisingBanner, idx) in advertisingBanners"
                                        :key="idx"
                                        @click="LinkBannerUrl(advertisingBanner.linkUrl)"
                                    >
                                        <div
                                            class="swiper-slide-img-wrapper">
                                            <img v-bind:src="advertisingBanner.imageUrl" v-bind:alt="advertisingBanner.altText" >
                                        </div>
                                        <p
                                            class="swiper-description">
                                            {{ advertisingBanner.publishesAt }}<br>
                                            {{ advertisingBanner.altText }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-button-prev"></div>
                            <div class="swiper-button-next"></div>
                        </div>
                    </section>
                    <!-- new-product -->
                </article>
            </main>
            <aside class="p-first-top-btn">
                <button class="p-first-top-btn__txt c-txt--xs">上に戻る</button>
            </aside>
            <!-- [/l-contents] -->
            <!-- <div class="l-footer-pagetop">
        <a href="#"  class="l-footer-pagetop__link">
            <span class="l-footer-pagetop__txt">Page Top</span>
        </a>
    </div>
    [/l-footer-pagetop] -->

<footer class="l-footer">
    <div class="l-footer__container">
        <div class="l-footer__copyright">
            <small class="l-footer__copyright-txt c-txt--xs"
                >copyright Aoba Publishing Co.,Ltd. All rights reserved.</small
            >
        </div>
    </div>
</footer>
<!-- [/l-footer] -->

<!-- modal -->
<InformationDetailModal ref="informationDetailModal" />
<TermsArea />
<ErrorModal ref="errorModal" />

<div
    class="l-common-contents__mask preview-video"
    id="topVideo"
>
    <div class="c-modal video" id="topVideoInner">
        <div class="c-modal__body" id="fullScreenContent">
            <div class="c-modal__video-wrapper">
                <video
                    id="video"
                    class="c-modal__video"
                    ref="video"
                    src="/assets/video/smn_support_movie.mp4"
                    @click="clickPlayButton()"
                    @loadeddata="loadeddataVideo()"
                    @timeupdate="timeupdateVideo()"
                    @ended="endedVideo()"
                ></video>
                <div class="p-preview-video__oper" id="controlBar">
                    <div class="p-preview-video__oper__container">
                        <div
                            class="p-preview__progress"
                        >
                            <input 
                                type="range" 
                                min="0" 
                                max="100" 
                                value="0" 
                                class="p-preview__progress-bar" 
                                ref="progressBar"
                                @input="inputProgressBar()"
                            >
                        </div>
                        <div class="p-preview-video__oper__inner">
                            <ul class="p-preview-video__oper__primary">
                                <li class="p-preview-video__oper__primary-item">
                                    <button
                                        id="playBtn"
                                        class="p-preview-view__playback"
                                        :class="{ 'is-play': isPlaying, 'is-stop': !isPlaying }"
                                        ref="playBtn"
                                        @click="clickPlayButton()"
                                    ></button>
                                </li>
                                <li class="p-preview-video__oper__primary-item">
                                    <button
                                        class="p-preview-view__volume"
                                        :class="{ 'is-on': !isMute, 'is-off': isMute }"
                                        ref="volumeBtn"
                                        @click="clickVolumeButton()"
                                    ></button>
                                    <div class="p-preview__volume">
                                        <input
                                            type="range"
                                            min="0"
                                            max="100"
                                            id="volumeBar"
                                            class="p-preview__volume-bar"
                                            ref="volumeBar"
                                            value="100"
                                            @input="inputVolumeBar()"
                                        >
                                    </div>
                                </li>
                                <li class="p-preview-video__oper__primary-item">
                                    <div class="p-preview-view__time c-txt">
                                      {{ currentTimeText }}
                                    </div>
                                </li>
                            </ul>
                            <button
                                class="p-preview-video__oper__secondary p-preview-view__screen"
                                id="fullScreenBtn"
                                @click="changeScreen()"
                            ></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-modal__foot">
            <button
                type="button"
                class="c-btn c-btn--l c-btn--bg-primary c-txt--l js-closeBtn"
            >
                <span>閉じる</span>
            </button>
        </div>
    </div>
</div>

        </div>
    </body>
</template>

