import { LS_SUFFIX_EXPIRES_ON } from "@/const";

const StorageExpiration = {
    check() {
        const removeKeys = [];
        let isExpiration = false;
        let isContainsCacheMemberType = false;
        let isContainsTextbooksExpires = false;

        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            // 会員種別のキャッシュ
            if (key === "cache_member_type_names") {
                const cacheMemberType = localStorage.getItem(key);
                if (cacheMemberType) {
                    isContainsCacheMemberType = true;
                }
            }
            // 教科書設定の有効期限
            if (key === "textbooks_expires_on") {
                isContainsTextbooksExpires = true;
            }

            // 有効期限のキーでない場合はスキップ
            if (!key.endsWith(LS_SUFFIX_EXPIRES_ON)) {
                continue;
            }

            // 有効期限が取得できない場合はスキップ
            const expireDate = localStorage.getItem(key);
            if (!expireDate) {
                continue;
            }

            // 有効期限が過ぎている場合は有効期限のキーと対応するキーを削除対象に追加
            const today = new Date();
            const todayDate = [today.getFullYear(), String(today.getMonth() + 1).padStart(2, "0"), String(today.getDate()).padStart(2, "0")];
            const todayString = todayDate.join("");
            if (parseInt(expireDate.split("-").join("")) < parseInt(todayString)) {
                removeKeys.push(key);
                removeKeys.push(key.replace(LS_SUFFIX_EXPIRES_ON, ""));
            }
        }

        // 削除対象のキーを削除
        for (const key of removeKeys) {
            localStorage.removeItem(key);
            isExpiration = true;
        }

        if (isContainsCacheMemberType && !isContainsTextbooksExpires) {
            // 会員種別のキャッシュ設定が存在する and 教科書設定の有効期限が存在しない
            isExpiration = true;
        }

        if (isExpiration) {
            // 20250401対応 後で消す シリアル番号をクリア
            window.$app.$cookies.set("serial_numbers", ["期限切れ"], new Date(new Date().getFullYear() + 1, 3, 1, 0, 0, 0));
            localStorage.removeItem("cache_member_type_names");
        }
    },
};

export default StorageExpiration;
