<!--
    FST002_settings_serial-number_first
    初回シリアル入力
-->
<script>
import axios from "axios";
import { API_URL_SERIAL_NUMBERS_VALID_COUNT, API_URL_SERIAL_NUMBERS_VALIDATE } from "@/const";
import LastAccessDate from "../common/LastAccessDate.js";
import StorageExpiration from "@/common/StorageExpiration";
import Cookie from "../common/Cookie.js";
import TermsArea from "@/components/TRM001_terms-use.vue";
import ErrorModal from "@/components/ErrorModal.vue";
import HeaderArea from "./Header.vue";
import Color from "../common/Color.js";
import Methods from "@/common/Methods";


// 初回表示数
const FIRST_DISPLAY_COUNT = 3;
// ステータスに対応するCSSクラス
const STATUS = {
  NONE: "",
  SUCCESS: "check",
  ERROR: "error",
  SAVED: "approved",
};

export default {
  components: {
    TermsArea,
    ErrorModal,
    HeaderArea,
  },
  data() {
    return {
      FIRST_DISPLAY_COUNT: FIRST_DISPLAY_COUNT,
      STATUS: STATUS,
      maxDigit: 8,
      serialNumbers: [],
      hiddenSerialNumbers: [],
      isHiddenAdditionalInput: true,
      isComposing: false,
      isShowDeleteModal: false,
      deleteTargetSerialNumber: "",
      lastSerialNumbers: Cookie.getOrDefault(Cookie.Key.SERIAL_NUMBERS),
      focusedSerialNumberIndex: null,
      focusedDigitIndex: null,
      isFocusedAddedElement: false,
      isExitsCookieSelectedSubject: false
    };
  },
  created() {
    Methods.checkMoveToTop() ? this.$router.push({ name: "FST001_first" }) : "";
    StorageExpiration.check();
    LastAccessDate.update();
    // cookieにselected_subjectが存在するか確認する
    this.isExitsCookieSelectedSubject = Cookie.exists(Cookie.Key.SELECTED_SUBJECT);
    // cookieにselected_subjectが存在する場合は、bodyの背景色を変更する
    if (this.isExitsCookieSelectedSubject) {
      this.selectedSubject = Cookie.get(Cookie.Key.SELECTED_SUBJECT);
      Color.changeColorBySubject(this.selectedSubject);
    }

    // 取得した有効シリアルコードの数だけ入力値を初期化
    axios
      .get(API_URL_SERIAL_NUMBERS_VALID_COUNT)
      .then((response) => {
        this.maxDigit = response.data.maxLength;

        for (let i = 0; i < response.data.validCount; i++) {
          const emptyDigits = [];
          for (let i = 0; i < this.maxDigit; i++) {
            emptyDigits.push("");
          }

          const serialNumber = {
            count: i + 1, // 連番
            digits: emptyDigits, // 入力値
            status: STATUS.NONE, // ステータス
            message: "", // 入力枠上のメッセージ
          };

          if (i <= this.lastSerialNumbers.length - 1) {
            serialNumber.digits = this.lastSerialNumbers[i].split("");
            serialNumber.status = STATUS.SAVED;

            // 桁数が足りない場合は空文字を追加
            for (let digitIndex = this.lastSerialNumbers[i].length; digitIndex < this.maxDigit; digitIndex++) {
              serialNumber.digits[digitIndex] = "";
            }
          }

          if (this.lastSerialNumbers.length > FIRST_DISPLAY_COUNT || i < FIRST_DISPLAY_COUNT) {
            this.serialNumbers.push(serialNumber);
          } else {
            this.hiddenSerialNumbers.push(serialNumber);
          }
        }
      })
      .then(() => {
        for (let i = 0; i < this.serialNumbers.length; i++) {
          const serialNumber = this.getJoinedSerialNumber(this.serialNumbers[i]);
          if (serialNumber === "") {
            continue;
          }

          axios
            .post(API_URL_SERIAL_NUMBERS_VALIDATE, {
              serialNumberValue: serialNumber,
            })
            .then((response) => {
              if (response.data.statusCode === 200) {
                this.serialNumbers[i].message = response.data.memberName;
              } else {
                this.serialNumbers[i].status = STATUS.ERROR;
                this.serialNumbers[i].message = response.data.errorMessage;
              }
            })
            .catch((error) => {
              console.log(error);
              this.openErrorModal(error.response.status);
            })
        }
      })
      .catch((error) => {
        console.log(error);
        this.openErrorModal(error.response.status);
      })
  },
  mounted() {
    // モーダル開閉トリガー
    const modalBtns = document.querySelectorAll('.modalOpen');
    modalBtns.forEach(function (btn) {
      btn.onclick = function () {
        const modal = btn.getAttribute('data-modal');
        document.getElementById(modal).classList.add('is-appear');
      };
    });

    // モーダル開閉トリガー
    const closeBtns = document.querySelectorAll('.modalClose');
    closeBtns.forEach(function (btn) {
      btn.onclick = function () {
        const modal = btn.closest('.modal');
        modal.classList.remove('is-appear');
      };
    });
  },
  computed: {
    /**
     * 次へ進めるかどうかを判定
     */
    allowNext() {
      const allSerialNumbers = this.serialNumbers.concat(this.hiddenSerialNumbers);

      let validCount = 0;
      for (const serialNumber of allSerialNumbers) {
        if (serialNumber.status === STATUS.ERROR) {
          // エラーが1つでもあればNG
          return false;
        }
        if (serialNumber.status === STATUS.SUCCESS) {
          validCount++;
        }
        if (serialNumber.status === STATUS.SAVED) {
          validCount++;
        }
      }
      // エラーがない かつ 有効なコードが1つでもあればOK
      return validCount > 0;
    },
  },
  methods: {
    /**
     * フォーカス時の処理
     */
    focus(event, serialNumberIndex = null, digitIndex = null, isAddedElement = false) {
      if (serialNumberIndex !== null) {
        // 入力項目の場合はテキストを選択状態にする
        event.target.select();
      }

      const lastFocusedSerialNumberIndex = this.focusedSerialNumberIndex;
      const lastFocusedDigitIndex = this.focusedDigitIndex;
      const wasFocusedAddedElement = this.isFocusedAddedElement;

      this.focusedSerialNumberIndex = serialNumberIndex;
      this.focusedDigitIndex = digitIndex;
      this.isFocusedAddedElement = isAddedElement;

      if (lastFocusedSerialNumberIndex === null || lastFocusedDigitIndex === null) {
        return;
      }

      // 最後にフォーカスされたシリアル番号行が今回のフォーカスと異なる場合
      // かつ 最後にフォーカスされたシリアル番号桁が最大桁の場合はバリデーションを行う
      if (lastFocusedSerialNumberIndex !== this.focusedSerialNumberIndex && lastFocusedDigitIndex + 1 === this.maxDigit)
      {
        if (wasFocusedAddedElement) {
          this.validate(this.hiddenSerialNumbers[lastFocusedSerialNumberIndex - this.FIRST_DISPLAY_COUNT]);
        } else {
          this.validate(this.serialNumbers[lastFocusedSerialNumberIndex]);
        }
      }
    },
    /**
     * キーアップ時の処理
     */
    keyup(event, targetSerialNumber) {
      const inputs = document.querySelectorAll(".js-serial-input");
      const input = event.target;

      // すべての桁数が埋まっている場合はシリアル番号判定を行う
      if (targetSerialNumber.digits.every((digit) => digit !== "") && !this.isComposing) {
        this.validate(targetSerialNumber);
      }

      if (event.key === 'Delete' || event.key === 'Backspace') {
        for (let i = 0; i < inputs.length; i++) {
          if (inputs[i] === input && i !== 0) {
            inputs[i - 1].focus();
            break;
          }
        }
      }
    },
    /**
     * キー入力時の処理
     */
    input(event, targetSerialNumber, digitIndex) {
      // 何かしら入力があった場合、ステータスをクリアする
      this.clear(targetSerialNumber);

      const inputs = document.querySelectorAll(".js-serial-input");
      const input = event.target;

      let inputValue = input.value;

      // IME入力中（変換中）の場合
      if (this.isComposing) {
        // 半角英数字以外の文字を削除
        let inputChars = inputValue.split("");
        inputChars = inputChars.filter((char) => {
          return char.match(/^[0-9a-zA-Z]+$/);
        });

        // 最後の１文字のみ残す
        inputValue = inputChars.join("");
        if (inputValue.length > 1) {
          inputValue = inputValue.slice(0, 1);
        }
        targetSerialNumber.digits[digitIndex] = inputValue;
        return;
      }

      if (inputValue.length > 1) {
        inputValue = inputValue.slice(-1);
        targetSerialNumber.digits[digitIndex] = inputValue;
      }

      if (!inputValue.match(/^[0-9a-zA-Z]*$/)) {
        inputValue = "";
        targetSerialNumber.digits[digitIndex] = inputValue;
        return;
      }

      if (input.value.length === 0) {
        return;
      }

      const lastInputIndex = this.isHiddenAdditionalInput
        ? (this.FIRST_DISPLAY_COUNT * this.maxDigit) - 1
        : inputs.length - 1;

        // フォーカス移動
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i] === input) {
          if (i < lastInputIndex) {
            inputs[i + 1].focus();
          } else {
            document.getElementById("hiddenElement").focus();
          }
          break;
        }
      }
    },
    /**
     * フォーカスアウト時の処理
     */
    blur(event, targetSerialNumber, digitIndex) {
      // IME入力中判定のままの場合は解除して入力値をクリア
      if (this.isComposing) {
        this.isComposing = false;
        targetSerialNumber.digits[digitIndex] = "";
      }
    },
    /**
     * シリアル番号判定
     */
    validate(targetSerialNumber) {
      if (targetSerialNumber === undefined) {
        return;
      }

      // シリアル番号未入力チェック
      if (this.getJoinedSerialNumber(targetSerialNumber) === "") {
        targetSerialNumber.status = STATUS.NONE;
        targetSerialNumber.message = "";
        return;
      }

      // シリアル番号重複チェック
      const allSerialNumbers = this.serialNumbers.concat(this.hiddenSerialNumbers);
      for (const serialNumber of allSerialNumbers) {
        if (serialNumber.count === targetSerialNumber.count) {
          continue;
        }
        if (this.getJoinedSerialNumber(serialNumber) === this.getJoinedSerialNumber(targetSerialNumber)) {
          targetSerialNumber.status = STATUS.ERROR;
          targetSerialNumber.message = "同じシリアル番号は入力できません。";
          return;
        }
      }

      // シリアル番号判定
      axios
        .post(API_URL_SERIAL_NUMBERS_VALIDATE, {
          serialNumberValue: this.getJoinedSerialNumber(targetSerialNumber),
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            targetSerialNumber.status = STATUS.SUCCESS;
            targetSerialNumber.message = response.data.memberName;
            return;
          }
          targetSerialNumber.status = STATUS.ERROR;
            targetSerialNumber.message = response.data.errorMessage;
        })
        .catch((error) => {
          console.log(error);
          this.openErrorModal(error.response.status);
        });
    },
    /**
     * 結合したシリアル番号を取得する
     * 空白はスペースと見なし、末尾のスペースは除去
     */
    getJoinedSerialNumber(serialNumber) {
      if (serialNumber === undefined) {
        return "";
      }

      let joinedSerialNumber = "";

      for (const digit of serialNumber.digits) {
        if (digit === "") {
          joinedSerialNumber += " ";
        } else {
          joinedSerialNumber += digit;
        }
      }

      return joinedSerialNumber.trimEnd();
    },
    /**
     * ステータス、メッセージクリア
     */
    clear(targetSerialNumber) {
      if(targetSerialNumber.status !== '') {
        targetSerialNumber.status = '';
      }
      if(targetSerialNumber.message !== '') {
        targetSerialNumber.message = '';
      }
    },
    /**
     * 追加入力エリアを表示
     */
    addInput() {
      this.isHiddenAdditionalInput = false;

      const showBtn = document.getElementById("showBtn");
      showBtn.style.display = "none";

      const showArea = document.getElementsByClassName("p-common-contents__serial-hide-area");
      showArea[0].style.display = "block";
    },
    /**
     * 後から設定するボタン押下時の処理
     */
    setLater() {
      Cookie.set(Cookie.Key.SERIAL_NUMBERS, []);

      // 学期制教科書設定（初回）に遷移
      this.$router.push({ name: "SET001_settings_textbook_first" });
    },
    /**
     * 次へボタン押下時の処理
     */
    next() {
      const allSerialNumbers = this.serialNumbers.concat(this.hiddenSerialNumbers);

      // シリアル番号を保存
      const serialNumbers = [];
      for (const serialNumber of allSerialNumbers) {
        if (serialNumber.status === STATUS.NONE || serialNumber.status === STATUS.ERROR) {
          continue;
        }
        serialNumbers.push(this.getJoinedSerialNumber(serialNumber));
      }
      Cookie.set(Cookie.Key.SERIAL_NUMBERS, serialNumbers);

      // 学期制教科書設定（初回）に遷移
      this.$router.push({ name: "SET001_settings_textbook_first" });
    },
    /**
     * 削除モーダルの表示切り替え
     */
    toggleDeleteModal(isShow, targetNumber = "") {
      this.isShowDeleteModal = isShow;
      this.deleteTargetSerialNumber = isShow ? targetNumber : "";
    },
    /**
     * 削除ボタン押下時の処理
     */
    deleteNumber() {
      const targetNumber = this.deleteTargetSerialNumber;
      if (targetNumber === "") {
        return;
      }

      // 入力値から削除
      const allSerialNumbers = this.serialNumbers.concat(this.hiddenSerialNumbers);
      for (const serialNumber of allSerialNumbers) {
        if (this.getJoinedSerialNumber(serialNumber) !== targetNumber) {
          continue;
        }
        serialNumber.status = STATUS.NONE;
        serialNumber.message = "";
        for (let i = 0; i < serialNumber.digits.length; i++) {
          serialNumber.digits[i] = "";
        }
      }

      // Cookieから削除
      const filteredSerialNumbers = this.lastSerialNumbers.filter((serialNumber) => {
        return serialNumber !== targetNumber;
      });
      Cookie.set(Cookie.Key.SERIAL_NUMBERS, filteredSerialNumbers);
      this.lastSerialNumbers = filteredSerialNumbers;

      this.isShowDeleteModal = false;
    },

    /**
     * エラーモーダルを開く
     */
    openErrorModal(statusCode) {
      this.$refs.errorModal.open(statusCode);
    },
  },
};
</script>

<template>
  <body class="input">
    <div class="l-wrapper">
      <HeaderArea
        :logoRedFlag="isExitsCookieSelectedSubject"
        :logoSideFlag="true"
        :menuFlag="false"
        />
      <TermsArea />
      <ErrorModal ref="errorModal" />

      <div
        class="l-common-contents__mask"
        :class="{ 'is-appear': isShowDeleteModal }"
        id="deleteMaskElement"
      >
        <div class="c-modal delete-confirm">
          <div class="c-modal__head delete-confirm">
            <div class="c-modal__title delete-confirm">
              シリアルコードを削除しますか？
            </div>
          </div>
          <div class="c-modal__body delete-confirm">
            <p class="p-delete-confirm__txt c-txt">
              シリアルコードを削除すると、それに関連するすべてのデータが削除されます。
              削除後は元に戻せません。
            </p>
          </div>
          <div class="c-modal__foot">
            <button
              type="button"
              class="c-btn c-btn--l c-btn--bg-secondary c-txt--l c-btn__txt"
              @click="deleteNumber()"
            >
              <span>削除</span>
            </button>
            <button
              type="button"
              class="c-btn c-btn--l c-btn--bg-primary c-txt--l"
              @click="toggleDeleteModal(false)"
            >
              <span>キャンセル</span>
            </button>
          </div>
        </div>
      </div>

      <main>
        <div class="l-common-contents__asd-less">
          <div class="l-common-contents__head">
            <a
              href="#"
              @click="() => $router.push({ name: 'FST001_first' })"
              class="p-common-contents__head__back-icon"
            >
              <img src="/assets/img/common/icon_arrow_black.svg" alt="" />
            </a>
            <div class="p-common-contents__head__title">
              教材のシリアルコードを設定
            </div>
          </div>
          <div class="l-common-contents__body-all" data-background="gray" style="margin-bottom: 5px">
            <div class="p-common-contents__body__serial">
              <div
                class="p-common-contents__serial-img"
                data-background="dark-gray"
              >
                <img
                  src="/assets/img/settings/common/img_serial_help.png"
                  alt="シリアル画像"
                />
              </div>
              <div class="p-common-contents__serial-content">
                <div class="p-common-contents__serial-content__head">
                  <p
                    class="p-common-contents__serial-content__explain c-txt--l"
                  >
                    対象商品（教師用）に添付されたシリアルコードを入力すると、対応する教材を利用することができます。
                  </p>
                  <p
                    class="p-common-contents__serial-content__explain-after c-txt--s"
                  >
                    シリアルコードを入力せず利用開始する場合は「後から設定する」を選択してください。
                  </p>
                  <a
                    href="https://aob.co.jp/digital/smn/#code"
                    target="_blank"
                    class="p-common-contents__serial-content__research c-txt--s c-txt--link"
                  >
                    シリアルコードの調べ方
                  </a>
                </div>
                <div
                  v-for="serialNumber, serialNumberIndex in serialNumbers"
                  :key="serialNumber.count"
                  class="p-common-contents__serial__info-code"
                >
                  <div class="p-common-contents__serial__txt-detail">
                    <span class="c-txt--s">
                      {{ `${serialNumber.count}個目のコード（任意）` }}
                    </span>
                    <span
                      class="c-txt--xxs c-txt--bold"
                      :class="serialNumber.status === STATUS.SAVED ? STATUS.SUCCESS : serialNumber.status"
                    >
                      {{ serialNumber.message }}
                    </span>
                  </div>
                  <div
                    class="p-common-contents__serial__container-input"
                    :class="serialNumber.status"
                  >
                    <input
                      v-for="(digit, digitIndex) in serialNumber.digits"
                      :key="digitIndex"
                      type="text"
                      class="js-serial-input"
                      style="margin: 0 0.1rem"
                      v-model="serialNumber.digits[digitIndex]"
                      @focus="focus($event, serialNumberIndex, digitIndex, false)"
                      @keyup="keyup($event, serialNumber)"
                      @input="input($event, serialNumber, digitIndex)"
                      @compositionstart="isComposing = true"
                      @compositionend="isComposing = false"
                      @blur="blur($event, serialNumber, digitIndex)"
                      :disabled="serialNumber.status === STATUS.SAVED"
                    />
                    <button
                      v-show="serialNumber.status === STATUS.SUCCESS || serialNumber.status === STATUS.SAVED"
                      class="p-common-contents__serial__trash"
                      @click="toggleDeleteModal(true, this.getJoinedSerialNumber(serialNumber))"
                    >
                      <img src="/assets/img/common/icon_trash-black.svg" alt="" />
                    </button>
                  </div>
                </div>
                <div
                  class="p-common-contents__serial-hide-area"
                >
                  <div
                    v-for="serialNumber, serialNumberIndex in hiddenSerialNumbers"
                    :key="serialNumber.count"
                    class="p-common-contents__serial__info-code"
                  >
                    <div class="p-common-contents__serial__txt-detail">
                      <span class="c-txt--s">
                        {{ `${serialNumber.count}個目のコード（任意）` }}
                      </span>
                      <span
                        class="c-txt--xxs c-txt--bold"
                        :class="serialNumber.status === STATUS.SAVED ? STATUS.SUCCESS : serialNumber.status"
                      >
                        {{ serialNumber.message }}
                      </span>
                    </div>
                    <div
                      class="p-common-contents__serial__container-input"
                      :class="serialNumber.status"
                    >
                      <input
                        v-for="(digit, digitIndex) in serialNumber.digits"
                        :key="digitIndex"
                        type="text"
                        class="js-serial-input"
                        style="margin: 0 0.1rem"
                        v-model="serialNumber.digits[digitIndex]"
                        @focus="focus($event, serialNumberIndex + FIRST_DISPLAY_COUNT, digitIndex, true)"
                        @keyup="keyup($event, serialNumber)"
                        @input="input($event, serialNumber, digitIndex)"
                        @compositionstart="isComposing = true"
                        @compositionend="isComposing = false"
                        @blur="blur($event, serialNumber, digitIndex)"
                        :disabled="serialNumber.status === STATUS.SAVED"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="serialNumbers.length + hiddenSerialNumbers.length > FIRST_DISPLAY_COUNT && lastSerialNumbers.length <= FIRST_DISPLAY_COUNT"
                  class="p-common-contents__body-all__body__btn-more"
                  id="showBtn"
                  @click="addInput()"
                >
                  <button type="button" class="c-txt--xs" @focus="focus($event)">
                    さらにコードを入力する
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button type="button" id="hiddenElement" @focus="focus($event)" />
          <div class="l-common-contents__foot">
            <div class="p-common-contents__double-btn">
              <div class="p-common-contents__double-btn__txt c-txt--s modalOpen" data-modal="modal-term">
                サービスを利用する場合、<span class="c-txt--link">利用規約</span>に同意したとみなします
              </div>
              <div class="p-common-contents__double-btn__container">
                <button
                  type="button"
                  @click="setLater()"
                  class="c-btn c-btn--bg-secondary c-txt--l c-btn__txt c-btn--l"
                >
                  <span>後から設定する</span>
                </button>
                <button
                  type="button"
                  @click="next()"
                  id="nextBtn"
                  class="c-btn c-btn--bg-primary c-txt--l c-btn--l"
                  :class="{ disabled: !allowNext }"
                >
                  <span>次へ</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      <!-- [/l-contents] -->
    </div>
    <!-- [/l-wrapper] -->
  </body>
</template>
