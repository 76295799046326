<!--
  サイドバーのコンポーネント
 -->

<template>
  <div class="l-contents-side">
    <aside class="l-common-contents-aside">
      <ul class="p-common-contents-aside__list">
        <li
          class="p-common-contents-aside__item"
          :class="{ 'is-current': majorSubject.subjectName === selectedSubject }"
          v-for="majorSubject in majorSubjectList"
          :key="majorSubject.subjectName"
          :id="SUBJECT_KEYS[majorSubject.subjectName]">
          <a
            href="javascript:void(0)"
            class="p-common-contents__btn"
            v-on:click="
              onSubjectClicked(SUBJECT_KEYS[majorSubject.subjectName])
            ">
            {{ majorSubject.subjectName.slice(0, 1) }}
          </a>
        </li>
        <li
          class="p-common-contents-aside__item"
          :class="{ 'is-current': minorSubjectList.some((subject) => subject.subjectName === selectedSubject) }"
          id="otherSubject"
        >
          <a href="javascript:void(0)" class="p-common-contents__btn">
            <img src="/assets/img/settings/common/icon_other.svg" alt="" />
            <span class="c-txt--xxxs">その他</span>
          </a>
          <ul class="p-common-contents-aside__other is-none">
            <li
              class="c-txt--s"
              v-for="minorSubject in minorSubjectList"
              :key="minorSubject.subjectName">
              <a
                v-on:click="
                  onSubjectClicked(SUBJECT_KEYS[minorSubject.subjectName])
                "
                :id="SUBJECT_KEYS[minorSubject.subjectName]">
                {{ minorSubject.subjectName }}
              </a>
            </li>
          </ul>
        </li>
        <li class="p-common-contents-aside__item">
          <a
            :href="isEnabledAI ? 'https://smn.aob-plus.jp/file/ai/OK2a.html':null"
            :target="isEnabledAI ? '_blank':null"
            class="p-common-contents__btn"
            :class="isEnabledAI ? 'ai enable':'ai disable'"
            v-on:mouseover="isEnabledAI ? null : showAIInformation()"
            v-on:mouseleave="isEnabledAI ? null : hideAIInformation()">
            <img class="normal"
              :src="
                isEnabledAI
                  ? '/assets/img/settings/common/icon_ai.svg'
                  : '/assets/img/settings/common/icon_ai-disabled.svg'" alt="" />
            <img class="hover" src="/assets/img/settings/common/icon_ai-hover.svg" alt="" />
            <div
              class="c-block-info"
              :class="{ 'is-appear': isAppearAIInfo }">
                <p class="c-block-info__txt c-txt--xs">
                  AIサービスを利用するには<br>
                  テスト会員のシリアルを入力してください
                </p>
            </div>
          </a>
        </li>
      </ul>
      <div
        class="p-common-contents-aside__settings"
        :class="{ active: isActiveSettings }">
        <a
          href="javascript:void(0)"
          @click="() => $router.push({ name: 'SET002_settings_textbook' })">
          <img
            :src="
              isActiveSettings
                ? '/assets/img/settings/common/icon_settings-active.svg'
                : '/assets/img/teaching-unit/common/icon_settings.svg'
            "
            alt="" />
          <span class="c-txt--xxxs">設定</span>
        </a>
      </div>
    </aside>

    <div v-if="!isShowSubjectOnly" class="l-aside-side-contents">
      <div class="l-aside-side-contents__title">{{ selectedSubject }}</div>
      <ul class="l-aside-side-contents__list">
        <li
          class="l-aside-side-contents__item c-txt--s"
          :class="{ 'is-current': isCurrentUnit }">
          <a href="#" @click="() => $router.push({ name: 'UNI001_units' })"
            >単元から探す
          </a>
        </li>
        <li
          class="l-aside-side-contents__item c-txt--s"
          :class="{ 'is-current': isCurrentMaterials }">
          <a href="#" @click="() => $router.push({ name: 'MTR001_materials' })"
            >教材から探す
          </a>
        </li>
        <li
          class="l-aside-side-contents__item c-txt--s"
          :class="{ 'is-current': isCurrentSearchContents }">
          <a
            href="#"
            @click="() => $router.push({ name: 'SRC001_search-contents' })"
            >キーワードで絞る
          </a>
        </li>
      </ul>
      <div class="l-aside-side-contents__container-semester">
        <div class="l-aside-side-contents__semester-title c-txt--xxs">学期</div>
        <div class="l-aside-side-contents__semester-list">
          <div
            class="l-aside-side-contents__semester-item c-txt--xxs"
            v-if="this.termType == 3">
            <label class="c-checkbox">
              <input
                type="checkbox"
                name="１学期"
                class="l-aside-side-contents__semester-item__checkbox"
                v-on:click="selectTerms()"
                value="1" />
              <span>1学期</span>
            </label>
          </div>
          <div
            class="l-aside-side-contents__semester-item c-txt--xxs"
            v-if="this.termType == 3">
            <label class="c-checkbox">
              <input
                type="checkbox"
                name="２学期"
                class="l-aside-side-contents__semester-item__checkbox"
                v-on:click="selectTerms()"
                value="2" />
              <span>2学期</span>
            </label>
          </div>
          <div
            class="l-aside-side-contents__semester-item c-txt--xxs"
            v-if="this.termType == 3">
            <label class="c-checkbox">
              <input
                type="checkbox"
                name="３学期"
                class="l-aside-side-contents__semester-item__checkbox"
                v-on:click="selectTerms()"
                value="3" />
              <span>3学期</span>
            </label>
          </div>
          <div
            class="l-aside-side-contents__semester-item c-txt--xxs"
            v-if="this.termType == 2">
            <label class="c-checkbox">
              <input
                type="checkbox"
                name="前期"
                class="l-aside-side-contents__semester-item__checkbox"
                v-on:click="selectTerms()"
                value="1" />
              <span>前期</span>
            </label>
          </div>
          <div
            class="l-aside-side-contents__semester-item c-txt--xxs"
            v-if="this.termType == 2">
            <label class="c-checkbox">
              <input
                type="checkbox"
                name="後期"
                class="l-aside-side-contents__semester-item__checkbox"
                v-on:click="selectTerms()"
                value="2" />
              <span>後期</span>
            </label>
          </div>
        </div>
      </div>
      <div class="l-aside-side-contents__grade">
        <div class="l-aside-side-contents__grade-title c-txt--xxs">学年</div>
        <ul class="l-aside-side-contents__grade-list">
          <li
            v-for="grade in gradeList"
            :key="grade.gradeName"
            class="l-aside-side-contents__grade-item c-txt--xs"
          >
            <label class="c-checkbox">
              <input
                type="checkbox"
                :name="grade.gradeName"
                :value="grade.gradeName"
                class="gradecheck"
                :disabled="grade.disabled"
                v-on:click="selectGrades($event)"/>
              <span>{{ grade.gradeName }}</span>
              <span class="l-aside-side-contents__grade-item__sub c-txt--xxs">
              </span>
            </label>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="l-common-contents__mask"
      :class="{ 'is-appear': hasNoTextcomp }">
      <div class="c-modal delete-confirm">
        <div class="c-modal__head delete-confirm">
          <div class="c-modal__title delete-confirm">
            教科書会社が未設定です
          </div>
        </div>
        <div class="c-modal__body delete-confirm">
          <p class="p-delete-confirm__txt c-txt" style="text-align: center">
            コンテンツを閲覧するには、教科書会社を設定してください
          </p>
        </div>
        <div class="c-modal__foot">
          <button
            type="button"
            class="c-btn c-btn--l c-btn--bg-secondary c-txt--l c-btn__txt"
            v-on:click="selectLater()">
            <span>後から設定する</span>
          </button>
          <button
            type="button"
            class="c-btn c-btn--l c-btn--bg-primary c-txt--l"
            @click="toSettingsTextbook">
            <span>設定する</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SUBJECT,
  SUBJECT_KEYS,
  SUBJECTS,
  API_URL,
  UNITS,
  MATERIALS,
  SUBJECT_GRADES,
  SCREEN_NAME,
} from "../const.js";
import Storage from "../common/Storage.js";
import axios from "axios";
import Cookie from "../common/Cookie.js";
import Color from "../common/Color.js";
import Methods from "@/common/Methods";

const MAJOR_SUBJECT_LIST_CACHE_KEY = "cache_major_subject_list";
const MINOR_SUBJECT_LIST_CACHE_KEY = "cache_minor_subject_list";
const SUBJECT_GRADES_MAP_CACHE_KEY = "cache_subject_grades_map";
const MEMBER_TYPE_NAMES_CACHE_KEY = "cache_member_type_names";
const ENABLE_AI_MEMBER_TYPE_NAME = ["テスト"];

export default {
  name: "SideBar",
  data() {
    return {
      isCurrentUnit: false,
      isCurrentMaterials: false,
      isCurrentSearchContents: false,
      selectedSubject: Cookie.getOrDefault(Cookie.Key.SELECTED_SUBJECT),
      termType: Number(Cookie.getOrDefault(Cookie.Key.TERM_TYPE)),
      data: [],
      subjectData: [],
      sideSubjectList: [],
      majorSubjectList: [],
      minorSubjectList: [],
      SUBJECT_KEYS: SUBJECT_KEYS,
      gradeList: [
        { gradeName: "１年", disabled: false },
        { gradeName: "２年", disabled: false },
        { gradeName: "３年", disabled: false },
        { gradeName: "４年", disabled: false },
        { gradeName: "５年", disabled: false },
        { gradeName: "６年", disabled: false },
      ],
      hasNoTextcomp: false,
      hasNoLocalStorage: false,
      hedaerElement: document.querySelector(".l-header"),
      textCompliance: [],
      isEnabledAI: false,
      isAppearAIInfo: false,
    };
  },
  props: {
    isShowSubjectOnly: Boolean,
    isActiveSettings: Boolean,
    material: {
      type: [Object, String],
      default: () => {
        return "";
      },
    },
    emitSubjectClicked: Function,
  },
  watch: {
    material: function (newVal) {
      if (this.newVal != "") {
        this.disableGradesAndTerms(newVal);
      }
    },
  },
  emits: [
    "getData",
    "openErrorModal",
  ],
  created() {
    // キャッシュした内容で初期化する
    const cachedMajorSubjectList = localStorage.getItem(MAJOR_SUBJECT_LIST_CACHE_KEY);
    if (cachedMajorSubjectList) {
      this.majorSubjectList = JSON.parse(cachedMajorSubjectList);
    }
    const cachedMinorSubjectList = localStorage.getItem(MINOR_SUBJECT_LIST_CACHE_KEY);
    if (cachedMinorSubjectList) {
      this.minorSubjectList = JSON.parse(cachedMinorSubjectList);
    }
    this.restoreCachedGradeList();
  },
  mounted() {
    if( Methods.checkMoveToTop()){
      this.$router.push({ name: "FST001_first" });
      return;
    }
    // cookieにselected_subjectが存在するか確認する
    const isExitsCookie = Cookie.exists(Cookie.Key.SELECTED_SUBJECT);

    // 存在しない場合は、cookieに国語を追加する
    if (!isExitsCookie) {
      Cookie.set(Cookie.Key.SELECTED_SUBJECT, "国語");
      // cookieに登録された教科の値を変更する
      this.selectedSubject = Cookie.getOrDefault(Cookie.Key.SELECTED_SUBJECT);
    }

    // 選択した学年の教科書準拠にローカルストレージの教科書準拠をセットする
    this.getTextbookComplianceFromStorageAndSet();
    // サイドバーの教科リストを取得する
    this.getSubject();
    // サイドバーのAIボタンの活性／非活性を設定する
    this.enableAIButton();

    // 選択した教科によって、bodyの色を変える
    Color.changeColorBySubject(this.selectedSubject);

    // 遷移したURLに応じて教科・画面名のセットをローカルストレージに保存する
    this.saveScreenGroup();

    // URLに応じて検索画面の(単元から探す・教材から探す・キーワードで絞る)の単元項目を活性状態にする
    this.addIsCurrentClassByUrl();

    // 「その他」をホバーした時の動作
    this.changePosition();
    this.appearSideBarContent();
    this.scrollAsideObserver();

    // 教科切り替え時・初期遷移時にローカルストレージで保存されている学年・学期の値によってチェックボックスのチェックを入れる
    this.changeSubjectCheckboxByLocalStorage();

    // 教科対応学年テーブルの値にによってチェックボックスを活性・非活性を制御する
    this.getSubjectGrades();

    // ローカルストレージから教科と学年に対応した教科書準拠を取得し、セットする(初期遷移時・教科選択時に実行する)
    this.getTextbookComplianceFromStorageAndSet();

    // 初期遷移時の検索
    this.packingJsonDataAndSearch();
  },
  computed: {
    /**
     * 次に遷移する検索グループを取得
     */
    getNextScreenGroup() {
      const defaultScreenGroup = "UNI";

      const screenGroups = Storage.getJson(Storage.Key.SELECTED_SCREEN_GROUPS);
      if (!screenGroups) {
        return defaultScreenGroup;
      }

      const lastScreenGroup = screenGroups.find(
        (screenGroup) => screenGroup.subject === this.selectedSubject
      );

      if (!lastScreenGroup) {
        return defaultScreenGroup;
      }

      return lastScreenGroup.screenGroup;
    },
  },
  methods: {
    /**
     * 教科選択時の処理
     */
    onSubjectClicked(subjectKey) {
      if (typeof this.emitSubjectClicked === "function") {
        this.emitSubjectClicked(subjectKey);
      } else {
        this.switchSubject(subjectKey);
      }
    },

    /*
     * クリックして教科を切り替えた時の処理
     */
    switchSubject(subject) {
      // クリックした教科の教科書準拠がローカルストレージに登録されているか確認する
      let isExistsTextComp = this.checkTextComp(SUBJECT[subject]);
      if (!isExistsTextComp) {
        this.hasNoTextcomp = true;
      } else {
        //選択された教科をselectedSubjectにセットする
        this.selectedSubject = SUBJECT[subject];

        this.restoreCachedGradeList();

        // cookieに登録された教科の値を変更する
        Cookie.set(Cookie.Key.SELECTED_SUBJECT, this.selectedSubject);

        // 選択した教科によって、bodyの色を変える
        Color.changeColorBySubject(this.selectedSubject);

        // URLに応じて検索画面の(単元から探す・教材から探す・キーワードで絞る)の単元項目を活性状態にする
        this.changeSubjectCheckboxByLocalStorage();

        // 教科対応学年テーブルの値によってチェックボックスを活性・非活性を制御する
        this.getSubjectGrades();

        // ローカルストレージから教科と学年に対応した教科書準拠を取得し、セットする(初期遷移時・教科選択時に実行する)
        this.getTextbookComplianceFromStorageAndSet();

        const nextScreenGroup = this.getNextScreenGroup;
        const nextScreenName = SCREEN_NAME[nextScreenGroup];

        // 次に遷移する画面によって遷移処理を切り分け
        if (nextScreenGroup !== "SRC") {
          // キーワード検索以外の場合は、検索を行いつつ画面遷移を行う
          this.$router.push({ name: nextScreenName });
          this.packingJsonDataAndSearch();
        } else {
          if (this.$route.name === nextScreenName) {
            // キーワード検索が連続する場合はリロードを行う
            this.$router.go({ name: nextScreenName, force: true });
          } else {
            this.$router.push({ name: nextScreenName });
          }
        }
      }
    },
    
    /*
     * その他の教科の表示調整
     */
    changePosition() {
      const AppearOtherSubjectBtn = document.getElementById("otherSubject");
      const otherSubject = document.querySelector(
        ".p-common-contents-aside__other"
      );
      const scrollContainer = document.querySelector(".l-contents-side");

      if (AppearOtherSubjectBtn) {
        let referenceX;
        let referenceY;
        let referenceWidth;

        // その他の教科の位置を調整する関数を定義
        const repeatChangePosition = (targetObj, referenceObj) => {
          referenceX = referenceObj.getBoundingClientRect().x;
          referenceY = referenceObj.getBoundingClientRect().y;
          referenceWidth = referenceObj.getBoundingClientRect().width;

          targetObj.style.left = `${referenceX + referenceWidth - 16}px`;
          targetObj.style.top = `${referenceY - 16}px`;
        };

        // その他の教科の位置を調整
        repeatChangePosition(otherSubject, AppearOtherSubjectBtn);

        // コンテンツ内スクロールが発火した時に、その他の教科の位置を再調整
        scrollContainer.addEventListener("scroll", () => {
          repeatChangePosition(otherSubject, AppearOtherSubjectBtn);
        });

        // スクロールが発火した時に、その他の教科の位置を再調整
        window.addEventListener("scroll", () => {
          repeatChangePosition(otherSubject, AppearOtherSubjectBtn);
        });
      }
    },

    /*
     *「その他」をホバーした時にコンテンツ制御
     *
     */

    appearSideBarContent() {
      // 「その他」のボタンの要素を取得する
      const AppearOtherSubjectBtn = document.getElementById("otherSubject");
      const otherSubject = document.querySelector(
        ".p-common-contents-aside__other"
      );

      if (AppearOtherSubjectBtn) {
        AppearOtherSubjectBtn.addEventListener("mouseover", () => {
          otherSubject.classList.remove("is-none");
          this.changePosition(otherSubject, AppearOtherSubjectBtn);
        });
        AppearOtherSubjectBtn.addEventListener("mouseleave", () => {
          otherSubject.classList.add("is-none");
        });
        otherSubject.addEventListener("mouseover", () => {
          otherSubject.classList.remove("is-none");
          this.changePosition(otherSubject, AppearOtherSubjectBtn);
        });
        otherSubject.addEventListener("mouseleave", () => {
          otherSubject.classList.add("is-none");
        });
      }
    },

    /*
     *  スクロールした時にサイドバーのヘッダー分を削除
     */

    scrollAsideObserver() {
      // 教科書設定画面の場合は、何もしない
      if (
        this.$route.name === "SRL001_settings_serial-number" ||
        this.$route.name === "SET002_settings_textbook"
      ) {
        return;
      }
      const headerElement = document.querySelector(".l-header");
      const asideBar = document.querySelector(".l-contents-side");

      if (asideBar) {
        const chngeAside = (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              asideBar.classList.remove("fixed");
            } else {
              asideBar.classList.add("fixed");
            }
          });
        };

        const observer = new IntersectionObserver(chngeAside);
        observer.observe(headerElement);
      }
    },

    /*
     *  ローカルストレージに選択した教科の学年を保存する
     */
    addIsCurrentClassByUrl() {
      const path = this.$route.path;
      if (path.includes("/units")) {
        this.isCurrentUnit = true;
      } else if (path.includes("/materials")) {
        this.isCurrentMaterials = true;
      } else if (path.includes("/search-contents")) {
        this.isCurrentSearchContents = true;
      }
    },

    /*
     * 学期が選択された場合の ロジックローカルストレージ登録~JSON作成~検索まで
     */

    selectTerms() {
      // ローカルストレージに選択したデータを取得する
      this.saveTermsDataToLocalStrage();
      this.packingJsonDataAndSearch();
    },

    /*
     * 学年が選択された場合のロジック ローカルストレージ登録~JSON作成~検索まで
     * @param {Object} clickedEvent クリックイベント
     */

    selectGrades(clickedEvent) {
      // ローカルストレージに選択したデータを取得する
      // 教科書準拠が登録されている教科か確認する
      let isExistsTextComp = this.textCompCorrespondToClickedGrades(
        this.selectedSubject,
        clickedEvent
      );
      if (!isExistsTextComp) {
        this.hasNoTextcomp = true;
      } else {
        this.saveGradesDataToLocalStrage();
        this.packingJsonDataAndSearch();
      }
    },

    /*
     * 教科切り替え時・初期遷移時にローカルストレージで保存されている学年・学期の値によってチェックボックスのチェックを入れる
     */

    changeSubjectCheckboxByLocalStorage() {
      // ローカルストレージの値を全て取得する
      let grades = Storage.get("selected_grades");
      let gradesData = JSON.parse(grades);

      // .gradecheckのチェックボックスを全て外す
      let gradeChecks = document.querySelectorAll(".gradecheck");
      gradeChecks.forEach((gradeCheck) => {
        gradeCheck.checked = false;
      });

      // selected-grades全てのオブジェクトからfindで選択中の教科のオブジェクトを取得する
      let selectedGradeObject = gradesData.find(
        (grade) => grade.subject == this.selectedSubject
      );

      // オブジェクトストレージに対応した学年に対してチェックボックスにチェックを入れる
      gradeChecks.forEach((gradeCheck) => {
        selectedGradeObject.grades.forEach((grade) => {
          if (gradeCheck.value == grade) {
            gradeCheck.checked = true;
          }
        });
      });

      // ローカルストレージから選択した学期を取得する
      let terms = Storage.get("selected_terms");
      if (terms) {
        terms = JSON.parse(terms);

        // 学期のチェックボックスを全て外す
        let semesterCheck = document.querySelectorAll(
          ".l-aside-side-contents__semester-item__checkbox"
        );
        semesterCheck.forEach((semesterCheck) => {
          semesterCheck.checked = false;
        });

        // selected_terms全てのオブジェクトからfindで選択中の教科のオブジェクトを取得する
        let selectedTermObject = terms.find(
          (term) => term.subject == this.selectedSubject
        );

        // オブジェクトストレージに対応した学期に対してチェックボックスにチェックを入れる
        semesterCheck.forEach((semesterCheck) => {
          selectedTermObject.terms.forEach((term) => {
            if (semesterCheck.value == term) {
              semesterCheck.checked = true;
            }
          });
        });
        // 期限切れ等で
        // どちらも同じ期限なので、取得可否は同じ
        if (!grades || !terms) {
          this.$router.push({ name: "SET002_settings_textbook" });
        }
      }
    },

    /*
     *学年のチェックボックスをクリック時に学期チェックボックスの値をローカルストレージに保存する
     */

    saveGradesDataToLocalStrage() {
      // 教科書準拠が登録されている教科か確認する
      let isExistsTextComp = this.checkTextComp(this.selectedSubject);
      if (!isExistsTextComp) {
        this.hasNoTextcomp = true;
      } else {
        // 学年のチェックボックスの値を取得する
        let gradeCheck = document.querySelectorAll(".gradecheck");

        // ①ローカルストレージ保存処理
        // ローカルストレージから選択した学年を取得する
        let grades = Storage.get("selected_grades");
        let gradesData = JSON.parse(grades);

        // ローカルストレージに学年を保存する
        gradesData.forEach((grade, index) => {
          if (grade.subject == this.selectedSubject) {
            gradesData[index].grades = [];
            gradeCheck.forEach((grade) => {
              if (grade.checked) {
                gradesData[index].grades.push(grade.value);
              }
            });
          }
        });
        // selectedGradesローカルストレージに保存する
        Storage.set("selected_grades", JSON.stringify(gradesData));
      }
    },

    /*
     *学期のボタンをクリック時に学期チェックボックスの値をローカルストレージに保存する
     */

    saveTermsDataToLocalStrage() {
      // 学期を取得する
      let semesterCheck = document.querySelectorAll(
        ".l-aside-side-contents__semester-item__checkbox"
      );

      // 学期のローカルストレージの値を取得する
      let terms = Storage.get("selected_terms");
      terms = JSON.parse(terms);

      // ローカルストレージに学期を保存する
      terms.forEach((term, index) => {
        if (term.subject == this.selectedSubject) {
          terms[index].terms = [];
          semesterCheck.forEach((semester) => {
            if (semester.checked) {
              terms[index].terms.push(semester.value);
            }
          });
        }
      });

      //terms内を全て数値型に変換する
      terms.forEach((term, index) => {
        terms[index].terms = terms[index].terms.map(Number);
      });

      // selectedTermsローカルストレージに保存する
      Storage.set("selected_terms", JSON.stringify(terms));
    },

    /*
     * JSONデータ作成処理 && コンテンツ検索処理
     */

    packingJsonDataAndSearch() {
      // JSONデータ作成処理
      // ローカルストレージから教科書準拠を取得する
      let textbooks = Storage.getJson(Storage.Key.TEXTBOOKS) ?? [];

      // ローカルストレージの値の中から該当の教科書準拠の学年を取得する
      let targetCompliance = textbooks.filter(
        (textbook) => textbook.subject == this.selectedSubject
      );

      // ローカルストレージの選択中の学年・教科書準拠を比較して、学年と一致するものオブジェクトを格納する
      // 学年のローカルストレージの値を取得する
      let grades = Storage.get("selected_grades");
      let gradesData = JSON.parse(grades);

      let targetGrades = gradesData.find(
        (grade) => grade.subject == this.selectedSubject
      );

      // 空のJSONを作成する
      let json = {
        gradeTextbookCompliances: [],
        terms: [],
        subjectName: this.selectedSubject,
        termType: this.termType,
      };

      // gradeTextbookCompliancesに値を追加する
      targetGrades.grades.forEach((grade) => {
        let targetComplianceObject = targetCompliance.find(
          (textbook) => textbook.grade == grade
        );
        if (targetComplianceObject) {
          json["gradeTextbookCompliances"].push({
            textbookComplianceName: targetComplianceObject.textbook,
            gradeName: targetComplianceObject.grade,
          });
        }
      });

      if (json["gradeTextbookCompliances"].length <= 0) {
        delete json["gradeTextbookCompliances"];
      }

      // ローカルストレージから選択した学期を取得する
      let terms = Storage.get("selected_terms");
      terms = JSON.parse(terms);

      // 選択されている学年に対応した学期を取得する
      json["terms"] = terms.find(
        (term) => term.subject == this.selectedSubject
      ).terms;

      // 学期が一つでも指定されていたら、JSONデータに学期を追加する
      if (json["terms"].length <= 0) {
        delete json["terms"];
      }

      // URLを取得する
      let url = this.getUrl();

      // シリアルナンバーが存在する場合に配列化する
      let serialNumbers = Cookie.getOrDefault(Cookie.Key.SERIAL_NUMBERS);

      // キーワード検索・教材コンテンツから学年・学期を切り替えた場合は、シリアルナンバーをJSONに追加する
      if (
        this.$route.path.includes("/search-contents") ||
        this.$route.name === "MTR002_materials_contents"
      ) {
        json["serialNumbers"] = serialNumbers;
      }

      if (url != "") {
        axios
          .post(url, json)
          .then((res) => {
            this.data = res.data;
            this.$emit("getData", this.data);
          })
          .catch((error) => {
            console.log("error: " + error);
            this.$emit('openErrorModal', error.response.status);
          });
      }

      if (this.$route.path.includes("/search-contents")) {
        this.$emit("getData", json);
      }
    },

    /*
     * APIに送るURLを取得する
     */
    getUrl() {
      const path = this.$route.path;
      let url = "";
      if (path.includes("/units")) {
        url = API_URL + UNITS;
      } else if (
        path.includes(`/materials/${this.$route.params.materialTypeId}/content`)
      ) {
        const materialTypeId = this.$route.params.materialTypeId;
        url = API_URL + MATERIALS + "/" + materialTypeId + "/contents";
      } else if (path.includes("/materials")) {
        url = API_URL + MATERIALS;
      }
      return url;
    },

    /*
     * サイドバーの教科アイコンを取得する
     */

    getSubject: async function () {
      const url = API_URL + SUBJECTS;
      await axios
        .get(url)
        .then((res) => {
          this.sideSubjectList = res.data.subjects;

          // 主要科目を配列に格納する
          this.majorSubjectList = this.sideSubjectList.filter(
            (subject) => subject.isMajor === true
          );
          localStorage.setItem(MAJOR_SUBJECT_LIST_CACHE_KEY, JSON.stringify(this.majorSubjectList));

          // 副科目を配列に格納する
          this.minorSubjectList = this.sideSubjectList.filter(
            (subject) => subject.isMajor === false
          );
          localStorage.setItem(MINOR_SUBJECT_LIST_CACHE_KEY, JSON.stringify(this.minorSubjectList));
        })
        .catch((error) => {
          console.log("error: " + error);
          this.$emit('openErrorModal', error.response.status);
        });
    },

    /*
     * 教科対応学年テーブルの値によってチェックボックスを活性・非活性を制御する
     */

    getSubjectGrades: async function () {
      const url = API_URL + SUBJECT_GRADES;
      await axios
        .get(url, {
          params: {
            subject: this.selectedSubject,
          },
        })
        .then((res) => {
          for (const grade of res.data.grades) {
            for (const gradeElement of this.gradeList) {
              if (gradeElement.gradeName === grade.gradeName) {
                gradeElement.disabled = !grade.exists;
                break;
              }
            }
          }
          this.saveSubjectGradesCache(this.selectedSubject, this.gradeList);
        })
        .catch((error) => {
          console.log("error: " + error);
          this.$emit('openErrorModal', error.response.status);
        });
    },

    /*
     * 遷移したURLに応じて教科・画面名のセットをローカルストレージに保存する
     */

    saveScreenGroup() {
      // ローカルストレージから値を取得する
      let screenGroupObjects = Storage.get("selected_screen_group");
      let screenType = this.$route.name.slice(0, 3);

      // 現在の画面が保存対象の画面グループに含まれない場合は何もしない
      if (!SCREEN_NAME[screenType]) {
        return;
      }

      // ローカルストレージに値がない場合は、空の配列を作成する
      if (screenGroupObjects == null) {
        screenGroupObjects = [
          {
            subject: this.selectedSubject,
            screenGroup: screenType,
          },
        ];
      } else {
        screenGroupObjects = JSON.parse(screenGroupObjects);
        // 該当の教科のローカルストレージが存在する場合は、そのオブジェクトを編集する
        screenGroupObjects.forEach((targetObject, index) => {
          if (targetObject.subject == this.selectedSubject) {
            // ローカルストレージに存在するオブジェクトのscreenGroupを編集する
            screenGroupObjects[index].screenGroup = screenType;
          }
        });

        // 該当の教科のローカルストレージが存在しない場合は、新たにオブジェクトを追加する
        let target = screenGroupObjects.find((targetObject) => {
          return targetObject.subject == this.selectedSubject;
        });

        if (target == undefined) {
          screenGroupObjects.push({
            subject: this.selectedSubject,
            screenGroup: screenType,
          });
        }
      }
      // ローカルストレージに値を保存する
      Storage.set("selected_screen_group", JSON.stringify(screenGroupObjects));
    },

    /*
     * 単元コンテンツに遷移した時にそのコンテンツの学年・学期に対応しないチェックボックスを非活性にする
     * param: material 単元コンテンツのデータ
     */

    disableGradesAndTerms(material) {
      let gradeCheck = document.querySelectorAll(".gradecheck");
      let semesterCheck = document.querySelectorAll(
        ".l-aside-side-contents__semester-item__checkbox"
      );

      // 教材が存在しない場合は、全てのチェックボックスを非活性にする
      if (material.materialTypes.length == 0) {
        gradeCheck.forEach((gradeCheck) => {
          gradeCheck.disabled = true;
        });
        semesterCheck.forEach((semesterCheck) => {
          semesterCheck.disabled = true;
        });
        return;
      }
    
      gradeCheck.forEach((gradeCheck) => {
        if (gradeCheck.value == material.gradeName) {
          gradeCheck.disabled = false;
          // gradeCheckの親要素にpointer-events:noneを付与する
          gradeCheck.parentNode.style.pointerEvents = "none";
        } else {
          gradeCheck.disabled = true;
        }
      });

      semesterCheck.forEach((semesterCheck) => {
        if (semesterCheck.name == material.termName) {
          semesterCheck.disabled = false;
          // semesterCheckの親要素にpointer-events:noneを付与する
          let target = semesterCheck.parentNode;
          target.parentNode.style.pointerEvents = "none";
        } else {
          semesterCheck.disabled = true;
        }
      });
    },

    /*
     * ローカルストレージに対象の学年の存在するか確認する
     * param: selectedSubject (教科選択時の場合はクリックした教科・初期遷移の場合は選択中の教科)
     */

    checkTextComp(selectedSubject) {
      // ローカルストレージにtextbooksが存在するか確認する
      let textbooks = Storage.getJson(Storage.Key.TEXTBOOKS);
      let isExists;

      if (textbooks !== null) {
        isExists = textbooks.some((textbook) => {
          return (
            textbook.subject == selectedSubject && textbook.textbook !== ""
          );
        });
      } else {
        // ローカルストレージに値がない場合は、falseを返す
        isExists = false;
      }
      return isExists;
    },

    /*
     *ローカルストレージから教科と学年に対応した教科書準拠を取得し、セットする(初期遷移時・教科選択時に実行する)
     */

    getTextbookComplianceFromStorageAndSet() {
      // ローカルストレージから教科書準拠を取得する
      let textbooks = Storage.getJson(Storage.Key.TEXTBOOKS) ?? [];

      // 対応した教科の教科書準拠を取得する
      let targetCompliance = textbooks.filter(
        (textbook) => textbook.subject == this.selectedSubject
      );

      // 教科のチェックボックスを取得する
      let gradeCheck = document.querySelectorAll(".gradecheck");

      // 学年のチェックボックスの個数分ループ
      gradeCheck.forEach((gradeCheck) => {
        // チェックボックスのvalueと教科書準拠のgradeが一致するものを取得する
        let targetGradeCompliance = targetCompliance.find(
          (textbook) => textbook.grade == gradeCheck.value
        );

        // 変更したい兄弟要素の1個前の要素を取得
        let previousTargetNode = gradeCheck.nextElementSibling;
        // 対象の学年の教科書準拠が存在しない場合
        if (!targetGradeCompliance) {
          // 対象の学年の教科書準拠を空文字にする
          previousTargetNode.nextElementSibling.innerHTML = "";
          // 対象の学年の教科書準拠が存在する場合
        } else if (targetGradeCompliance.grade == gradeCheck.value) {
          // 対象の学年のローカルストレージの教科書準拠をテキストしてセット
          previousTargetNode.nextElementSibling.innerHTML =
            targetGradeCompliance.textbook;
        }
      });
    },

    /**
     * 教科書設定画面へ遷移する
     */
    toSettingsTextbook() {
      // 現在の画面が既に教科書設定画面であればモーダルを閉じる
      if (this.$route.path === "/settings/textbook") {
        this.hasNoTextcomp = false;
        return;
      }

      this.$router.push({ name: "SET002_settings_textbook" });
    },

    /*
     *  モーダルで後で設定するを選択した場合モーダルを閉じる
     */

    selectLater() {
      this.hasNoTextcomp = false;
    },

    /*
     *  教科書準拠で選択した学年が存在しない場合は、モーダルを表示する
     *  param: selectedSubject (選択中の教科)
     *  param: clickedGrades (クリックした学年)
     */

    textCompCorrespondToClickedGrades(selectedSubject, clickedEvent) {
      // ローカルストレージにtextbooksが存在するか確認する
      let textbooks = Storage.getJson(Storage.Key.TEXTBOOKS);
      let isExists;
      let clickedGrades = clickedEvent.target.value;

      if (textbooks !== null) {
        isExists = textbooks.some((textbook) => {
          return (
            clickedGrades == textbook.grade &&
            textbook.subject == selectedSubject &&
            textbook.textbook !== ""
          );
        });
      } else {
        // ローカルストレージに値がない場合は、falseを返す
        isExists = false;
      }

      // 教科書準拠が存在しない、対応の学年のチェック状態をfalseにする
      if (!isExists) {
        clickedEvent.target.checked = false;
      }

      return isExists;
    },

    /**
     * 選択中の教科の学年リストのキャッシュを保存する
     */
    saveSubjectGradesCache(subjectName, gradeList) {
      const subjectGrades = localStorage.getItem(SUBJECT_GRADES_MAP_CACHE_KEY);

      const subjectGradesMap = subjectGrades
        ? new Map(Object.entries(JSON.parse(subjectGrades)))
        : new Map();

      subjectGradesMap.set(subjectName, gradeList);

      const json = Object.fromEntries(subjectGradesMap);
      localStorage.setItem(SUBJECT_GRADES_MAP_CACHE_KEY, JSON.stringify(json));
    },

    /**
     * キャッシュした学年リストを復元する
     */
    restoreCachedGradeList() {
      const cached = localStorage.getItem(SUBJECT_GRADES_MAP_CACHE_KEY);
      if (!cached) {
        return;
      }

      const json = JSON.parse(cached);
      const subjectGradesMap = new Map(Object.entries(json));
      if (subjectGradesMap.has(this.selectedSubject)) {
        this.gradeList = subjectGradesMap.get(this.selectedSubject);
      }
    },

    /**
     * 更新チェック
     */
     enableAIButton() {
      const cachedMemberTypeNames = localStorage.getItem(MEMBER_TYPE_NAMES_CACHE_KEY);
      if (cachedMemberTypeNames) {
        const memberTypeNames = cachedMemberTypeNames.toString().split("・") 
        this.isEnabledAI = false
        for (const key in memberTypeNames) {
          if (ENABLE_AI_MEMBER_TYPE_NAME.indexOf(memberTypeNames[key]) > -1) {
                this.isEnabledAI = true
          }
        }
      }
    },

    /*
     * 非活性のAIボタンをホバーした時にコンテンツを表示
     */
     showAIInformation() {
      this.isAppearAIInfo = true;
    },

    /*
     * 非活性のAIボタンから離れた時にコンテンツを非表示
     */
    hideAIInformation() {
      this.isAppearAIInfo = false;
    },
  },
};
</script>
