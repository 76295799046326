<template>
  <header class="l-header settings">
    <div class="l-header__container">
      <div class="l-header__container-logo">
        <h1 class="l-header__logo">
          <a
              href="javascript:void(0)"
              class="l-header__logo-link"
              @click="onLinkClicked('FST001_first')"
          >
            <svg
              v-if="logoRedFlag"
              width="127"
              height="40"
              viewBox="0 0 127 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="theme-color"
                d="M40.2578 1.88937L42.6624 1.45117L42.3774 6.96948L40.2578 1.88937Z"
                fill="#E44A4A"
              />
              <path
                class="theme-color"
                d="M44.0483 0L38.4941 1.01127L41.6939 8.67806L43.6225 8.23023L44.0483 0Z"
                fill=""
              />
              <path
                class="theme-color"
                d="M32.9424 5.34958L34.8767 4.03027L37.146 8.88998L32.9424 5.34958Z"
                fill="#E44A4A"
              />
              <path
                class="theme-color"
                d="M35.4299 2.17847L30.917 5.25646L37.3894 10.708L38.8961 9.60137L35.4299 2.17847Z"
                fill=""
              />
              <path
                class="theme-color"
                d="M53.1207 3.17432L55.3939 4.57868L50.293 8.82819L53.1207 3.17432Z"
                fill="#E44A4A"
              />
              <path
                class="theme-color"
                d="M52.5699 1.40845L48.5576 9.43214L50.1617 10.5412L57.4803 4.4418L52.5699 1.40845Z"
                fill=""
              />
              <path
                d="M55.9046 12.8989H28.8232V18.5389H48.2293L42.839 23.2964L38.8714 20.2485L34.789 24.5347L48.9974 35.4498L53.0798 31.1637L47.629 26.9762L58.1064 17.729C59.0149 16.9283 59.2934 15.7098 58.8116 14.652C58.3286 13.5918 57.1798 12.8989 55.9046 12.8989Z"
                fill="white"
              />
              <path
                d="M25.6003 17.3432C26.1255 16.4712 26.0998 15.4134 25.5325 14.5598C24.9665 13.7086 23.9418 13.1853 22.8364 13.1853H1.08335V18.8253H17.0536C13.5809 22.8361 8.87483 26.177 2.79682 28.9604L0 30.243L2.85439 35.2658L5.65121 33.9855C10.3075 31.8513 14.2706 29.4092 17.5955 26.6223L27.4391 35.4608L31.8806 31.4728L21.8494 22.4658C23.2596 20.8556 24.5133 19.1533 25.6003 17.3432Z"
                fill="white"
              />
              <path
                d="M81.6389 8.78687H75.3576V13.1856H60.7148V18.8256H75.3576V19.3373C75.3576 24.6148 71.0862 28.1531 68.3914 29.1442C66.9873 29.6627 65.311 29.7316 63.8555 29.7316H60.7148V35.3716H63.8555C65.9228 35.3716 68.3621 35.2522 70.7743 34.3619C75.1415 32.7557 81.6389 27.4278 81.6389 19.3373V18.8256H89.7565V13.1856H81.6389V8.78687Z"
                fill="white"
              />
              <path
                d="M113.247 29.603C113.178 29.6054 106.443 29.6949 103.135 29.5892C98.2985 29.4308 98.0685 27.8131 98.0685 22.7238V22.6221C102.266 21.8007 107.124 20.6223 111.882 19.4586L115.886 18.4764L114.235 13.0361L110.229 14.0183C106.061 15.0378 101.805 16.0744 98.0685 16.8503V8.78687H91.7871V22.7238C91.7871 26.9665 91.7871 34.8619 102.906 35.2245C104.221 35.2682 105.994 35.2818 107.708 35.2818C110.521 35.2818 113.17 35.2452 113.346 35.2428L116.486 35.197L116.387 29.5593L113.247 29.603Z"
                fill="white"
              />
              <path
                d="M121.301 8.85327H117.376V15.0325H121.301V8.85327Z"
                fill="white"
              />
              <path
                d="M126.72 8.85327H122.795V15.0325H126.72V8.85327Z"
                fill="white"
              />
            </svg>
            <img
              v-else
              src="/assets/img/settings/header/icon_setings-logo.svg"
              alt="スマナビ"
            />
          </a>
        </h1>
        <div class="l-header-settings__logo-side c-txt--xs" v-if="logoSideFlag">
          <span v-if="memberTypeNames">利用可能:{{ memberTypeNames }}</span>
        </div>
      </div>
      <nav class="l-header-settings__nav" v-if="menuFlag">
        <ul class="l-header-settings__list">
          <li class="l-header-settings__item">
            <button id="headerClickContentPrimary">
              <img src="/assets/img/common/header/icon_question.svg" alt="" />
              <div class="l-header-settings__item-txt c-txt--xs">
                使い方・よくある質問
              </div>
            </button>
            <ul
              class="l-header-settings__container__contents l-header-settings__container__contents-primary"
              id="headerAppearContentPrimary"
            >
              <li>
                <a
                  href="https://aob.co.jp/digital/smn/"
                  target="_blank"
                  class="c-txt--s"
                >
                  使い方</a
                >
              </li>
              <li>
                <a
                  @click="onLinkClicked('HLP001_help')"
                  class="c-txt--s"
                >
                  よくある質問
                </a>
              </li>
            </ul>
          </li>
          <li class="l-header-settings__item">
            <button 
                id="headerClickContentSecondary"
                @mouseover="resetHeaderNewsScroll()"
                @click="resetHeaderNewsScroll()"
            >
              <img src="/assets/img/common/header/icon_bell.svg" alt="" />
              <div class="l-header-settings__item-txt c-txt--xs">お知らせ</div>
            </button>
            <div
              class="l-header-settings__container__contents l-header-settings__container__contents-secondary"
              id="headerAppearContentSecondary"
            >
              <template v-if="informationList.length > 0">
                <ul
                  class="js-headerNews"
                  :class="{ 'is-scroll': informationList.length > 3 }"
                >
                    <li
                      v-for="(information, idx) in informationList"
                      :key="idx"
                      @click="LinkOrOpenInfoModal(information)"
                    >
                      <span class="l-header-settings__contents-type c-txt--xxxs">
                        {{ information.informationTypeName }}
                      </span>
                      <span class="l-header-settings__contents-date c-txt--xxxs">
                        {{ information.displayDate }}
                      </span>

                      <p class="l-header-settings__contents-txt c-txt--xs">
                        {{ information.title }}
                      </p>
                    </li>
                </ul>
              </template>
              <template v-else>
                <ul class="js-headerNews">
                  <li>
                    <p class="l-header-settings__contents-txt c-txt--xs">
                      お知らせはありません
                    </p>
                  </li>
                </ul>
              </template>
            </div>
          </li>
          <li class="l-header-settings__item">
            <a
              href="#"
              @click="onLinkClicked('SRL001_settings_serial-number')"
              class="l-header-settings__item-serial"
            >
              <img src="/assets/img/common/header/icon_lock.svg" alt="" />
              <div class="l-header-settings__item-txt c-txt--xs">
                シリアルコード追加
              </div>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </header>

  <InformationDetailModal ref="informationDetailModal" />
</template>

<script>
import axios from "axios";
import { API_URL, API_URL_MEMBER_TYPES } from "../const.js";
import Cookie from "../common/Cookie.js";
import Methods from "@/common/Methods";
import InformationDetailModal from "@/components/InformationDetailModal.vue"

const MEMBER_TYPE_NAMES_CACHE_KEY = "cache_member_type_names";

export default {
  name: "HeaderArea",
  components: {
    InformationDetailModal,
  },
  data() {
    return {
      informationList: [],
      memberTypeNames: null,
    };
  },
  props: {
    logoRedFlag: {
      type: Boolean,
      default: false,
    },
    logoSideFlag: {
      type: Boolean,
      default: false,
    },
    menuFlag: {
      type: Boolean,
      default: false,
    },
    emitLinkClicked: Function,
  },
  emits: [
    "openErrorModal",
  ],
  created() {
    // キャッシュした内容で初期化する
    this.memberTypeNames = localStorage.getItem(MEMBER_TYPE_NAMES_CACHE_KEY) || null;
  },
  mounted() {
    axios
      .get(API_URL + "/informations")
      .then((res) => {
        this.informationList = res.data;
      })
      .catch((error) => {
        console.log("error: " + error);
        this.$emit('openErrorModal', error.response.status);
      });

    let serialNumbers = Cookie.getOrDefault(Cookie.Key.SERIAL_NUMBERS);
    let json = {
      serialNumbers: serialNumbers,
    };

    axios
      .post(API_URL_MEMBER_TYPES, json)
      .then((res) => {
        this.memberTypeNames = res.data.memberTypeNames.join("・");
        localStorage.setItem(MEMBER_TYPE_NAMES_CACHE_KEY, this.memberTypeNames);
      })
      .catch((error) => {
        console.log("error: " + error);
        this.$emit('openErrorModal', error.response.status);
      });
  },
  methods: {
    LinkOrOpenInfoModal(information) {
      if (information.body) {
        this.$refs.informationDetailModal.open(information);
      } else if (information.linkUrl) {
        Methods.openTab(information.linkUrl);
      }
    },
    onLinkClicked(screenName) {
      if (typeof this.emitLinkClicked === "function") {
        this.emitLinkClicked(screenName);
      } else {
        this.$router.push({ name: screenName });
      }
    },
    resetHeaderNewsScroll() {
      let headerNews = document.getElementsByClassName("js-headerNews")[0];
      headerNews.scrollTop = 0;
    },
  },
};
</script>
